import { useMemo } from "react";

import { ChatAPIConversationContextField } from "api/types/responses";
import TagRespuesta from "components/Feedback/Respuestas/TablaRespuestas/TagRespuesta";
import { getTags } from "helpers/tags";

import LoaderChat from "../LoaderChat";
import "./RespuestasChat.css";

const textoQuestionsActions = "action";

interface RespuestasChatProps {
  datos: ChatAPIConversationContextField[] | undefined;
  tags:
    | {
        question: string;
        question_id: string;
        tag: string;
      }[]
    | undefined;
  computed:
    | {
        display_name: string;
        value: string;
      }[]
    | undefined;
}

const RespuestasChat = ({ datos, tags, computed }: RespuestasChatProps) => {
  const tagsInActions = tags?.filter(
    (t) => t.question !== textoQuestionsActions,
  );

  const tagsFixMulticitas = useMemo(() => {
    if (!datos || !tagsInActions) {
      return undefined;
    }
    const nCitas = datos.find((d) => d.title === "N Citas")?.value as
      | number
      | undefined;
    if (!nCitas) {
      return tagsInActions;
    }
    return tagsInActions.filter(
      (t) =>
        t.question.search(/[0-9]\?$/) < 0 ||
        Number(t.question.slice(-2)[0]) <= nCitas,
    );
  }, [datos, tagsInActions]);

  return (
    <div className="RespuestasChat">
      <h2 className="RespuestasChat__titulo">Respuestas</h2>
      {tagsFixMulticitas && computed?.length === 0 ? (
        tagsFixMulticitas.map(({ question, tag }, i) => (
          <div key={`tag-chat-${i}`} className="DatosChat__contenedor_header">
            <div className="DatosChat__nombre_header">{question}</div>
            <div className="DatosChat__valor_header">
              {getTags(tag).map((tag) => (
                <TagRespuesta key={tag.id} tag={tag} pregunta={question} />
              ))}
            </div>
          </div>
        ))
      ) : computed && computed.length > 0 ? (
        computed.map(({ display_name, value }, i) => (
          <div key={`tag-chat-${i}`} className="DatosChat__contenedor_header">
            <div className="DatosChat__nombre_header">{display_name}</div>
            <div className="DatosChat__valor_header">
              {getTags(value).map((tag) => (
                <TagRespuesta key={tag.id} tag={tag} pregunta={display_name} />
              ))}
            </div>
          </div>
        ))
      ) : (
        <LoaderChat />
      )}
    </div>
  );
};

export default RespuestasChat;
