import { useQuery } from "@tanstack/react-query";

import { configFieldsApi } from "feedback-api";
import type { GetConfigFieldsResponse } from "feedback-api";

const useConfigFieldsQuery = (formName: string) => {
  return useQuery<GetConfigFieldsResponse, unknown>({
    queryKey: ["configFields", formName],
    queryFn: async ({ signal }) => {
      const { data } = await configFieldsApi.getConfigFieldsV4ConfigFieldsGet(
        formName,
        { signal },
      );
      return data;
    },
    refetchInterval: 300_000,
  });
};

export default useConfigFieldsQuery;
