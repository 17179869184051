import { setUser as setSentryUser } from "@sentry/react";
import axios, { HttpStatusCode, isAxiosError } from "axios";
import { format } from "date-fns";

import { NoTokenError } from "feedback-api/configuration";
import store from "store";
import { limpiaEncuestas } from "store/slices/encuestas";
import { cierraLaSesion } from "store/slices/login";

import {
  AnswerHeadersAPIResponse,
  AnswersAPIResponse,
  InputHeadersAPIResponse,
  CreatePollUsersAPIResponse,
  ChatAPIResponse,
  UsageAPIResponse,
  ReactionsAPIResponse,
  AnswerMediaAPIResponse,
} from "./types/responses";

const API_ROOT = process.env.REACT_APP_LEGACY_API_ROOT;

const axiosInstance = axios.create({ baseURL: API_ROOT });

axiosInstance.interceptors.request.use((config) => {
  const token = store.getState().login.token;
  if (!token) {
    throw new NoTokenError();
  }
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

axiosInstance.interceptors.response.use(undefined, (error: unknown) => {
  if (
    isAxiosError(error) &&
    error.response?.status === HttpStatusCode.Unauthorized
  ) {
    setSentryUser(null);
    store.dispatch(cierraLaSesion());
    store.dispatch(limpiaEncuestas());
  }
  return Promise.reject(error);
});

export const headersRespuestas = (idEncuesta: number, signal?: AbortSignal) => {
  return axiosInstance.get<AnswerHeadersAPIResponse>(
    `answer_headers/${idEncuesta}`,
    { signal },
  );
};

export const respuestas = (
  idEncuesta: number,
  fechaInicio: number | Date,
  fechaTermino: number | Date,
  signal?: AbortSignal,
) => {
  const url = `answers/${idEncuesta}`;
  const params = {
    fecha_inicio: format(fechaInicio ?? Date.now(), "yyyy-MM-dd 00:00"),
    fecha_termino: format(fechaTermino ?? Date.now(), "yyyy-MM-dd 23:59"),
  };
  return axiosInstance.get<AnswersAPIResponse>(url, { signal, params });
};

export const consultarMapping =
  (idEncuesta: number) =>
  ({ signal }: { signal?: AbortSignal }) => {
    const url = `input_headers/${idEncuesta}`;
    return axiosInstance.get<InputHeadersAPIResponse>(url, { signal });
  };

export const crearEncuestas = ({
  idEncuesta,
  datos,
}: {
  idEncuesta: number;
  datos: unknown;
}) => {
  const url = `poll_users/${idEncuesta}`;
  return axiosInstance.post<CreatePollUsersAPIResponse>(url, {
    continue: "True",
    data: datos,
  });
};

export const chat = (
  idEncuesta: number | string,
  idUsuario: number | string,
  signal?: AbortSignal,
) => {
  const url = `chat/${idEncuesta}/${idUsuario}`;
  return axiosInstance.get<ChatAPIResponse>(url, { signal });
};

export const uso = (
  fechaInicio: string,
  fechaTermino: string,
  signal?: AbortSignal,
) => {
  const url = "usage";
  const params = {
    fecha_inicio: fechaInicio,
    fecha_termino: fechaTermino,
  };
  return axiosInstance.get<UsageAPIResponse>(url, { params, signal });
};

export const exportarRespuestas = (
  idEncuesta: number,
  fechaInicio: number | Date,
  fechaTermino: number | Date,
  email: string,
  attachment_extension: "csv" | "xlsx",
) => {
  const date_start = format(fechaInicio, "yyyy-MM-dd");
  const date_end = format(fechaTermino, "yyyy-MM-dd");
  const url = `report_beta/${idEncuesta}`;
  return axiosInstance.post<{ status: "OK"; data: null }>(url, {
    date_start,
    date_end,
    email,
    attachment_extension,
  });
};

export const obtenerReacciones = (
  idEncuesta: number | string,
  idUsuario: number | string,
  start: string,
  signal?: AbortSignal,
) => {
  const url = `reactions/${idEncuesta}/${idUsuario}`;
  return axiosInstance.get<ReactionsAPIResponse>(url, {
    params: { start },
    signal,
  });
};

export const agregarReaccion = (
  idEncuesta: number | string,
  idUsuario: number | string,
  start: string,
  emoji: string,
  texto: string | null,
) => {
  const url = `reactions/${idEncuesta}/${idUsuario}`;
  return axiosInstance.post<{ status: "OK"; data: null }>(url, {
    start,
    emoji,
    text: texto,
  });
};

export const eliminarReaccion = (
  idEncuesta: number | string,
  idUsuario: number | string,
  idReaccion: number,
) => {
  const url = `reactions/${idEncuesta}/${idUsuario}`;
  return axiosInstance<{ status: "OK"; data: number }>({
    url,
    method: "delete",
    data: { id: idReaccion },
  });
};

export const obtenerContenidoMultimedia = (
  idRespuesta: number,
  signal?: AbortSignal,
) => {
  const url = `answer_media/${idRespuesta}`;
  return axiosInstance.get<AnswerMediaAPIResponse>(url, { signal });
};
