import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import useAnswerHeadersQuery from "api/hooks/useAnswerHeadersQuery";
import { obtenerHeaders } from "helpers/tablaRespuestas";
import { getTag } from "helpers/tags";
import useAnalytics from "hooks/useAnalytics";
import { encuestasSelector } from "store/slices/encuestas";
import { agregaFiltro, respuestasSelector } from "store/slices/respuestas";

import LoaderRespuestas from "../TablaRespuestas/LoaderRespuestas";
import TagRespuesta from "../TablaRespuestas/TagRespuesta";
import "./ResumenRespuestas.css";

const tagsAMostrar = [
  { tag: "YES", alias: ["PHONE:YES"] },
  { tag: "NO", alias: ["FALLECIO_OTRO", "PHONE:NO"] },
  { tag: "REAGENDA", alias: [] },
  { tag: "OUT", alias: ["PHONE:OUT"] },
];

interface ResumenRespuestasProps {
  cargando: boolean;
}

const ResumenRespuestas = ({ cargando }: ResumenRespuestasProps) => {
  const { data: headers } = useAnswerHeadersQuery();
  const { idEncuestaSeleccionada } = useSelector(encuestasSelector);
  const { respuestasVisibles: respuestas } = useSelector(respuestasSelector);
  const dispatch = useDispatch();
  const headersOrdenados = idEncuestaSeleccionada
    ? obtenerHeaders(headers, idEncuestaSeleccionada)
    : null;
  const primerTag = headersOrdenados?.find(
    (h) => h.tipo === "YESNO" || h.tipo === "COMPUTED",
  );
  const isComputed = primerTag?.tipo === "COMPUTED";
  const track = useAnalytics();

  const conteosTags = useMemo(() => {
    if (cargando || !respuestas) {
      return {};
    }
    const primerHeaderYESNO = headersOrdenados?.find(
      (h) => h.tipo === "YESNO" || h.tipo === "COMPUTED",
    );
    return primerHeaderYESNO
      ? respuestas.reduce((prev: Record<string, number>, respuesta) => {
          const tagRespuesta =
            "f" in primerHeaderYESNO
              ? primerHeaderYESNO.f(respuesta)?.tag
              : respuesta[primerHeaderYESNO.nombre]?.tag ||
                respuesta[primerHeaderYESNO.nombre];
          const indice = tagsAMostrar.find(
            (t) => t.tag === tagRespuesta || t.alias.includes(tagRespuesta),
          )?.tag;
          if (indice) {
            prev[indice] = (prev[indice] ?? 0) + 1;
          }
          return prev;
        }, {})
      : {};
  }, [cargando, headersOrdenados, respuestas]);

  const { total, conRespuesta, porcentaje } = useMemo(() => {
    if (respuestas) {
      const primerHeaderYESNO = headersOrdenados?.find(
        (h) => h.tipo === "YESNO" || h.tipo === "COMPUTED",
      );
      if (!primerHeaderYESNO) {
        return { total: 0, conRespuesta: 0, porcentaje: 0 };
      } else {
        const total = respuestas.length;
        const conRespuesta = respuestas.reduce((prev, respuesta) => {
          const tagRespuesta =
            "f" in primerHeaderYESNO
              ? primerHeaderYESNO.f(respuesta)?.tag
              : isComputed
                ? respuesta[primerHeaderYESNO.nombre]
                : respuesta[primerHeaderYESNO.nombre]?.tag;
          if (tagRespuesta && tagRespuesta !== "UNREACHABLE") {
            return prev + 1;
          }
          return prev;
        }, 0);
        const porcentaje = (100 * conRespuesta) / total || 0;
        return { total, conRespuesta, porcentaje };
      }
    } else {
      return { total: 0, conRespuesta: 0, porcentaje: 0 };
    }
  }, [respuestas, headersOrdenados, isComputed]);

  return (
    <div className="ResumenRespuestas">
      <div
        className="ResumenRespuestas__detalle"
        // @ts-expect-error setting CSS variable through style prop
        style={{ "--porcentaje-lleno": `${porcentaje}%` }}
      >
        {cargando || !idEncuestaSeleccionada ? (
          <LoaderRespuestas />
        ) : (
          <>
            <div className="ResumenRespuestas__detalle_tasa">
              <div>
                Respondidas {conRespuesta.toLocaleString("de-DE")} de{" "}
                {total.toLocaleString("de-DE")}
              </div>
              <div className="ResumenRespuestas__porcentaje">
                {porcentaje.toLocaleString("de-DE", {
                  maximumFractionDigits: 1,
                })}
                %
              </div>
            </div>
            {/* TODO: replace table with grid layout */}
            <table className="ResumenRespuestas__detalle_tabla">
              <tbody>
                {tagsAMostrar.map(({ tag }) => {
                  const porcentaje = (100 * conteosTags[tag]) / total || 0;
                  return (
                    <tr
                      key={`fila-respuestas-${tag}`}
                      title={`${porcentaje.toLocaleString("de-DE", {
                        maximumFractionDigits: 1,
                        minimumFractionDigits: 1,
                      })}% de respuestas "${getTag(tag).texto}"`}
                    >
                      <td>
                        <div
                          className="ResumenRespuestas__tag"
                          onClick={() => {
                            if (!primerTag) {
                              // There are no YESNO columns over which to apply a filter
                              return;
                            }
                            track(
                              "Feedback",
                              "Respuestas",
                              "filtrarPorTagEnResumen",
                              { tags: getTag(tag).texto },
                            );
                            dispatch(
                              agregaFiltro({
                                busqueda: isComputed ? tag : getTag(tag).id,
                                nombreHeader: primerTag.nombre,
                                textoHeader: primerTag.texto,
                                idEncuesta: idEncuestaSeleccionada,
                              }),
                            );
                          }}
                        >
                          <TagRespuesta tag={getTag(tag)} />
                        </div>
                      </td>
                      <td
                        className="ResumenRespuestas__celda_barra"
                        style={{
                          // @ts-expect-error setting CSS variable through style prop
                          "--porcentaje-lleno": `${Math.min(
                            100,
                            2 * porcentaje,
                          )}%`,
                        }}
                      >
                        {conteosTags[tag]?.toLocaleString("de-DE") || 0}
                      </td>
                      <td
                        className="ResumenRespuestas__celda_barra"
                        style={{
                          // @ts-expect-error setting CSS variable through style prop
                          "--porcentaje-lleno": `${Math.max(
                            0,
                            2 * porcentaje - 100,
                          )}%`,
                        }}
                      >
                        {porcentaje.toLocaleString("de-DE", {
                          maximumFractionDigits: 1,
                          minimumFractionDigits: 1,
                        })}
                        %
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        )}
      </div>
    </div>
  );
};

export default ResumenRespuestas;
