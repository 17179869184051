import { Icon } from "@iconify/react";

import Button from "components/atoms/Button";
import Loader from "components/atoms/Loader";
import Modal from "components/atoms/Modal";
import { humanizeFullDate } from "utils/date";

import "./ConfirmModal.css";

interface ConfirmModalProps {
  isOpen?: boolean;
  isCreating?: boolean;
  appointmentsCount: number;
  professionalName: string;
  start: Date;
  end: Date;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmModal = ({
  isOpen = false,
  isCreating = false,
  appointmentsCount,
  professionalName,
  start,
  end,
  onConfirm,
  onCancel,
}: ConfirmModalProps) => {
  const patientCount = `${appointmentsCount} paciente${appointmentsCount === 1 ? "" : "s"}`;
  return (
    <Modal isOpen={isOpen} onRequestClose={onCancel}>
      <div className="ConfirmModal__info">
        <h2 className="ConfirmModal__title">
          Confirmación de envío de mensajes
        </h2>
        <p className="ConfirmModal__copy">
          Estás a punto de enviar mensajes a <em>{patientCount}</em>. ¿Deseas
          continuar?
        </p>
        <div className="ConfirmModal__data_container">
          <div className="ConfirmModal__data_element">
            <div className="ConfirmModal__data_element__header">
              <Icon icon="uil:users-alt" height="1rem" />
              Pacientes afectados
            </div>
            <div className="ConfirmModal__data_element__value">
              {patientCount}
            </div>
          </div>
          <div className="ConfirmModal__data_element">
            <div className="ConfirmModal__data_element__header">
              <Icon icon="uil:user-square" height="1rem" />
              Profesional
            </div>
            <div className="ConfirmModal__data_element__value">
              {professionalName}
            </div>
          </div>
          <div className="ConfirmModal__data_element">
            <div className="ConfirmModal__data_element__header">
              <Icon icon="uil:calendar-alt" height="1rem" />
              Fecha de inicio
            </div>
            <div className="ConfirmModal__data_element__value">
              {humanizeFullDate(start)}
            </div>
          </div>
          <div className="ConfirmModal__data_element">
            <div className="ConfirmModal__data_element__header">
              <Icon icon="uil:calendar-alt" height="1rem" />
              Fecha de término
            </div>
            <div className="ConfirmModal__data_element__value">
              {humanizeFullDate(end)}
            </div>
          </div>
        </div>
      </div>
      <div className="ConfirmModal__actions">
        <Button variant="outline" onClick={onCancel} disabled={isCreating}>
          Volver
        </Button>
        <Button onClick={onConfirm} disabled={isCreating}>
          {isCreating ? (
            <>
              <Loader color="white" size="1rem" />
              Procesando notificaciones
            </>
          ) : (
            <>Notificar pacientes</>
          )}
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
