import { createContext, Dispatch } from "react";

import { createNewSuspensionContextState } from "./NewSuspensionContextState";
import { NewSuspensionAction } from "./NewSuspensionReducer";

const throwErrorDispatch: Dispatch<NewSuspensionAction> = () => {
  throw new Error(
    "Cannot use dispatch outside of NewSuspensionDispatchContext Provider",
  );
};
export const NewSuspensionContext = createContext(
  createNewSuspensionContextState(),
);
export const NewSuspensionDispatchContext =
  createContext<Dispatch<NewSuspensionAction>>(throwErrorDispatch);
