import Loader from "components/atoms/Loader";

import "./LoaderChat.css";

const LoaderChat = () => {
  return (
    <div className="LoaderChat">
      <Loader color="var(--color-bordes)" size="2rem" />
    </div>
  );
};

export default LoaderChat;
