import Loader from "components/atoms/Loader";

import "./LoaderMensajes.css";

const LoaderMensajes = () => {
  return (
    <div className="LoaderMensajes">
      <Loader color="#075e54" size="4rem" />
    </div>
  );
};

export default LoaderMensajes;
