import { useQuery } from "@tanstack/react-query";

import { FormCustomMetas } from "components/Feedback/Suspensions/types";
import {
  GenerateGlobalSettingPreviewResponse,
  suspensionsApi,
} from "feedback-api";

const useGlobalSettingsPreviewsQuery = (
  customExternalMeta?: FormCustomMetas,
) => {
  return useQuery<GenerateGlobalSettingPreviewResponse, unknown>({
    enabled: !!customExternalMeta,
    queryKey: ["generalSettingsPreviews", customExternalMeta],
    queryFn: async ({ signal }) => {
      const { data } =
        await suspensionsApi.generateGlobalSettingPreviewV4SuspensionsGlobalSettingPreviewPost(
          {
            custom_external_meta: customExternalMeta as
              | Record<string, string | boolean>
              | undefined,
          },
          { signal },
        );
      return data;
    },
  });
};

export default useGlobalSettingsPreviewsQuery;
