import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";

import useMetricsQuery from "api/hooks/useMetricsQuery";
import {
  downloadDashboardScreenshot,
  downloadDashboardData,
} from "components/Feedback/Dashboard/utils";
import Loader from "components/atoms/Loader";
import useAnalytics from "hooks/useAnalytics";
import { dashboardSelector } from "store/slices/dashboard";

import "./DownloadDashboardDataButton.css";

const DownloadDashboardDataButton = () => {
  const { data, isPending } = useMetricsQuery();
  const { start, end } = useSelector(dashboardSelector);
  const track = useAnalytics();

  if (!data) {
    return <Loader />;
  }

  return (
    <div className="DownloadDashboardDataButton">
      <button
        className="DownloadDashboardDataButton__main_button"
        onClick={() => {
          track("Feedback", "Dashboard", "downloadDashboardData");
          downloadDashboardData({ startDate: start, endDate: end, data });
        }}
        title="Descargar datos en formato Excel"
        disabled={isPending || !data}
      >
        <Icon icon="mdi:download-box" />
        Descargar
      </button>

      <button
        className="DownloadDashboardDataButton__secondary_button"
        onClick={() => {
          track("Feedback", "Dashboard", "downloadDashboardScreenshot");
          downloadDashboardScreenshot();
        }}
        title="Descargar captura del dashboard"
        disabled={isPending || !data}
      >
        <Icon icon="mdi:camera" />
      </button>
    </div>
  );
};

export default DownloadDashboardDataButton;
