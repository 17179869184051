import { Icon } from "@iconify/react";
import { rankItem } from "@tanstack/match-sorter-utils";
import {
  createColumnHelper,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Row,
  useReactTable,
} from "@tanstack/react-table";
import classNames from "classnames";
import * as _ from "lodash-es";
import { useState } from "react";

import { SuspensionRowData } from "api/hooks/useSuspensionsQuery";
import Skeleton from "components/Skeleton";
import Table from "components/atoms/Table";
import PaginationControls from "components/molecules/PaginationControls";
import { humanizeMidDate } from "utils/date";

import "./SuspensionsTable.css";

const columnHelper = createColumnHelper<SuspensionRowData>();

const getColumns = () => [
  columnHelper.accessor("professionalName", {
    header: "Profesional",
    cell: (info) => (
      <strong className="SuspensionsTable__ellipsed">
        {_.startCase(info.getValue()?.toLowerCase())}
      </strong>
    ),
    sortingFn: "text",
    sortUndefined: "last",
  }),
  columnHelper.accessor("affectedAppointments", {
    header: "Citas afectadas",
    cell: (info) =>
      `${info.getValue()} cita${info.getValue() !== 1 ? "s" : ""}`,
    sortingFn: "alphanumeric",
    sortDescFirst: true,
  }),
  columnHelper.accessor("rangeStart", {
    header: "Rango de citas",
    cell: (info) => {
      const start = info.getValue();
      const end = info.row.original.rangeEnd;
      return start && end
        ? `${humanizeMidDate(new Date(start))} - ${humanizeMidDate(new Date(end))}`
        : null;
    },
    sortingFn: "datetime",
    sortDescFirst: true,
    sortUndefined: "last",
  }),
  columnHelper.accessor("centerName", {
    header: "Sucursal",
    cell: (info) => (
      <span className="SuspensionsTable__ellipsed">{info.getValue()}</span>
    ),
    sortingFn: "text",
    sortUndefined: "last",
  }),
  columnHelper.accessor("effectiveInteractionsRatio", {
    header: "Respondidas",
    cell: (info) => {
      const effective = info.row.original.effectiveInteractions;
      const total = info.row.original.affectedAppointments;
      return (
        <>
          <strong>{(info.getValue() * 100).toFixed(0)}%</strong> ({effective} de{" "}
          {total})
        </>
      );
    },
    sortingFn: "alphanumeric",
    sortDescFirst: true,
  }),
  columnHelper.accessor("createdAt", {
    header: "Fecha de envío",
    cell: (info) =>
      new Date(info.getValue()).toLocaleDateString("es-PY", {
        dateStyle: "medium",
      }),
    sortingFn: "datetime",
    sortDescFirst: true,
    sortUndefined: "last",
  }),
  columnHelper.accessor("createdBy", {
    header: "Creador (a)",
    cell: (info) => info.getValue(),
    sortingFn: "text",
    sortUndefined: "last",
  }),
];

const fuzzyFilter: FilterFn<unknown> = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value);
  addMeta({
    itemRank,
  });
  return itemRank.passed;
};

interface SuspensionsTableProps {
  suspensions: SuspensionRowData[] | undefined;
}

const SuspensionsTable = ({ suspensions }: SuspensionsTableProps) => {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const table = useReactTable({
    data: suspensions ?? [],
    columns: getColumns(),
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: { pagination },
  });

  const { rows } = table.getRowModel();

  if (!suspensions) {
    return (
      <div className="SuspensionsTable">
        <Table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {Array.from({ length: pagination.pageSize }).map((_, i) => (
              <tr key={i}>
                {Array.from({ length: getColumns().length }).map((_, j) => (
                  <td key={j}>
                    <Skeleton />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th colSpan={getColumns().length}>
                <div className="SuspensionsTable__pagination">
                  <Skeleton />
                  <Skeleton />
                </div>
              </th>
            </tr>
          </tfoot>
        </Table>
      </div>
    );
  }
  return (
    <div className="SuspensionsTable">
      <Table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                  className={classNames({
                    SuspensionsTable__sortable: header.column.getCanSort(),
                  })}
                >
                  <div className="SuspensionsTable__header_wrapper">
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                    {header.column.getIsSorted() === "asc" && (
                      <Icon
                        className="SuspensionsTable__sort_icon"
                        icon="uil:arrow-up"
                      />
                    )}
                    {header.column.getIsSorted() === "desc" && (
                      <Icon
                        className="SuspensionsTable__sort_icon"
                        icon="uil:arrow-down"
                      />
                    )}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {rows.map((row: Row<SuspensionRowData>) => {
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <th colSpan={getColumns().length}>
              <div className="SuspensionsTable__pagination">
                <span>
                  Mostrando{" "}
                  <strong>
                    {pagination.pageIndex * pagination.pageSize + 1}&ndash;
                    {Math.min(
                      (pagination.pageIndex + 1) * pagination.pageSize,
                      suspensions.length,
                    )}
                  </strong>{" "}
                  de <strong>{suspensions.length}</strong>
                </span>
                <PaginationControls
                  pages={
                    1 +
                    Math.trunc((suspensions.length - 1) / pagination.pageSize)
                  }
                  current={pagination.pageIndex + 1}
                  setCurrent={(newIndex) =>
                    setPagination({ ...pagination, pageIndex: newIndex - 1 })
                  }
                />
              </div>
            </th>
          </tr>
        </tfoot>
      </Table>
    </div>
  );
};

export default SuspensionsTable;
