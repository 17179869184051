import { InlineIcon } from "@iconify/react";
import classNames from "classnames";
import * as _ from "lodash-es";
import { useEffect, useMemo, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";

import { AnswerHeader, ComputedHeader } from "api/types/domain";
import { getTag } from "helpers/tags";
import useAnalytics from "hooks/useAnalytics";
import { encuestasSelector } from "store/slices/encuestas";
import { ESQUEMA_OSCURO, opcionesSelector } from "store/slices/opciones";
import {
  agregaFiltro,
  ordenaRespuestas,
  remueveFiltro,
  respuestasSelector,
} from "store/slices/respuestas";

import TagRespuesta from "../TagRespuesta";
import "./ModalFiltros.css";

interface ModalFiltrosProps {
  i: number;
  header: AnswerHeader | ComputedHeader | undefined;
  activo: boolean;
  containerClass: string;
  esconder: () => void;
}

const ModalFiltros = ({
  i,
  header,
  activo,
  containerClass,
  esconder,
}: ModalFiltrosProps) => {
  const [ancho, setAncho] = useState(0);
  const { filtros, ordenHeader, orden, categorias } =
    useSelector(respuestasSelector);
  const { esquema } = useSelector(opcionesSelector);
  const { idEncuestaSeleccionada } = useSelector(encuestasSelector);
  const filtroRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const indiceFiltro = filtros.findIndex(
    (f) => f.headers[0] === header?.nombre,
  );
  const filtro = indiceFiltro >= 0 ? filtros[indiceFiltro] : undefined;
  const container =
    i >= 0 && document.getElementsByClassName(containerClass)[i];
  const { left, top, width } = useMemo(
    () =>
      (container && container.getBoundingClientRect()) || {
        left: 0,
        top: 0,
        width: 0,
      },
    [container],
  );
  const track = useAnalytics();
  const trackBusqueda = useRef(
    _.debounce(
      (props) =>
        track("Feedback", "Respuestas", "filtrarPorColumnaSegunTexto", props),
      2_000,
    ),
  ).current;

  useEffect(() => {
    setAncho(document.getElementsByClassName("ModalFiltros")[0]?.clientWidth);
  }, [filtro, header]);

  useEffect(() => {
    if (activo && filtroRef.current) {
      let busqueda = filtro?.busqueda?.length === 1 && filtro.busqueda[0];
      if (busqueda && busqueda.startsWith("TAG")) {
        busqueda = busqueda.slice(3, -3);
      }
      filtroRef.current.value = busqueda || "";
      filtroRef.current.focus();
    }
  }, [filtro, activo]);
  const anchoTotal = left + width + ancho;

  if (!header || !idEncuestaSeleccionada) {
    return null;
  }

  const portalContainer = document.getElementById("modal-filtros");
  if (!portalContainer) {
    return null;
  }

  const ordenarRespuestas = () => {
    track("Feedback", "Respuestas", "ordenarPorColumna", {
      header: header.nombre,
      idEncuesta: idEncuestaSeleccionada,
    });
    dispatch(
      ordenaRespuestas({
        header: header.nombre,
        idEncuesta: idEncuestaSeleccionada,
      }),
    );
  };

  const categoria = categorias.find((c) => c.propiedad === header.nombre);
  let nivelesHeader =
    categoria?.niveles.filter((x) => categoria.esTag || x) || [];

  if (nivelesHeader.includes("YES") && nivelesHeader.includes("PHONE:YES")) {
    nivelesHeader = nivelesHeader.filter((n) => n !== "PHONE:YES");
  }

  if (nivelesHeader.includes("NO") && nivelesHeader.includes("PHONE:NO")) {
    nivelesHeader = nivelesHeader.filter((n) => n !== "PHONE:NO");
  }

  if (nivelesHeader.includes("OUT") && nivelesHeader.includes("PHONE:OUT")) {
    nivelesHeader = nivelesHeader.filter((n) => n !== "PHONE:OUT");
  }

  return ReactDOM.createPortal(
    <div
      className={classNames({
        ModalFiltros__lamina: true,
        "ModalFiltros__lamina--oculta": !activo,
      })}
      onClick={esconder}
    >
      <div
        className={classNames({
          ModalFiltros: true,
          ModalFiltros__oscuro: esquema === ESQUEMA_OSCURO,
        })}
        style={{
          left:
            anchoTotal >= window.innerWidth
              ? `${left - ancho}px`
              : `${left + width}px`,
          top,
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <button className="ModalFiltros__boton" onClick={ordenarRespuestas}>
          <InlineIcon
            icon={
              header.nombre === ordenHeader
                ? orden === "ASC"
                  ? "mdi:sort-descending"
                  : "mdi:sort-ascending"
                : "mdi:sort"
            }
          />
          {header.nombre === ordenHeader
            ? orden === "ASC"
              ? "Orden ascendente"
              : "Orden descendente"
            : "Ordenar"}
        </button>
        {nivelesHeader.length > 0 && nivelesHeader.length < 50 && (
          <div className="ModalFiltros__contenedor_niveles">
            <div className="ModalFiltros__contenedor_checkbox_nivel">
              <button
                className="ModalFiltros__checkbox_nivel"
                onClick={() => dispatch(remueveFiltro(indiceFiltro))}
              >
                <InlineIcon
                  icon={
                    filtro
                      ? "mdi:checkbox-blank-outline"
                      : "mdi:checkbox-marked"
                  }
                  className="ModalFiltros__icono_checkbox_nivel"
                />{" "}
                Mostrar todo
              </button>
            </div>
            {nivelesHeader.map((nivel, i) => (
              <div
                key={`contenedor-filtro-categoria-${i}`}
                className="ModalFiltros__contenedor_checkbox_nivel"
              >
                <button
                  className="ModalFiltros__checkbox_nivel"
                  onClick={() => {
                    track("Feedback", "Respuestas", "filtrarPorColumna", {
                      header: header.nombre,
                    });
                    dispatch(
                      agregaFiltro({
                        busqueda: categoria?.esTag ? getTag(nivel).id : nivel,
                        nombreHeader: header.nombre,
                        textoHeader: header.texto,
                        idEncuesta: idEncuestaSeleccionada,
                        opciones: {
                          mismaColumna: true,
                          calceExacto: true,
                        },
                      }),
                    );
                  }}
                >
                  {categoria?.esTag ? (
                    <>
                      <InlineIcon
                        icon={
                          filtro?.busqueda?.includes(
                            "TAG" + getTag(nivel).texto + "TAG",
                          )
                            ? "mdi:checkbox-marked"
                            : "mdi:checkbox-blank-outline"
                        }
                        className="ModalFiltros__icono_checkbox_nivel"
                      />
                      <TagRespuesta
                        tag={getTag(nivel)}
                        pregunta={getTag(nivel).texto}
                        incluirSinRespuesta={true}
                      />
                    </>
                  ) : (
                    <>
                      <InlineIcon
                        icon={
                          filtro?.busqueda?.includes(nivel)
                            ? "mdi:checkbox-marked"
                            : "mdi:checkbox-blank-outline"
                        }
                        className="ModalFiltros__icono_checkbox_nivel"
                      />{" "}
                      <>{nivel || "(Vacío)"}</>
                    </>
                  )}
                </button>
              </div>
            ))}
          </div>
        )}
        <button
          className="ModalFiltros__boton"
          onClick={() => filtroRef.current?.focus()}
        >
          <InlineIcon icon="mdi:filter" />
          <input
            className="ModalFiltros__input_filtro"
            ref={filtroRef}
            onChange={(e) => {
              trackBusqueda({
                header: header.nombre,
                busqueda: e.target.value,
              });
              dispatch(
                agregaFiltro({
                  busqueda: e.target.value,
                  nombreHeader: header.nombre,
                  textoHeader: header.texto,
                  idEncuesta: idEncuestaSeleccionada,
                }),
              );
            }}
            placeholder="Escribe para filtrar"
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === "Escape") {
                esconder();
              }
            }}
          />
        </button>
        <button
          className="ModalFiltros__boton_limpiar_filtro"
          onClick={() => {
            track("Feedback", "Respuestas", "limpiarFiltroPorTextoEnColumna", {
              header: header.nombre,
            });
            dispatch(
              agregaFiltro({
                busqueda: "",
                nombreHeader: header.nombre,
                textoHeader: header.texto,
                idEncuesta: idEncuestaSeleccionada,
              }),
            );
          }}
          title="Limpiar filtro"
        >
          <InlineIcon icon="mdi:close" />
        </button>
      </div>
    </div>,
    portalContainer,
  );
};

export default ModalFiltros;
