import { useMutation, useQueryClient } from "@tanstack/react-query";

import { suspensionsApi, UpdateGlobalSettingsRequest } from "feedback-api";

const useUpdateGlobalSettingsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, UpdateGlobalSettingsRequest>({
    mutationFn: async (payload) => {
      await suspensionsApi.updateGlobalSettingsV4SuspensionsUpdateGlobalSettingsPost(
        payload,
      );
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ["configFields", "configure_suspension"],
      }),
  });
};

export default useUpdateGlobalSettingsMutation;
