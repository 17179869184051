import { Icon, InlineIcon } from "@iconify/react";
import {
  CellContext,
  ColumnDef,
  createColumnHelper,
  HeaderContext,
  RowSelectionState,
} from "@tanstack/react-table";
import { formatISO } from "date-fns";
import * as _ from "lodash-es";
import { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import useGetSuspensionCandidatesQuery from "api/hooks/useGetSuspensionCandidatesQuery";
import Tooltip from "components/Tooltip";
import Button from "components/atoms/Button";
import Checkbox from "components/atoms/Checkbox";
import Loader from "components/atoms/Loader";
import { CandidateAppointment } from "feedback-api";
import { addToast } from "store/slices/toasts";
import { humanizeFullDate } from "utils/date";

import {
  NewSuspensionContext,
  NewSuspensionDispatchContext,
} from "../NewSuspensionContext";
import { getErrorMessageForCode } from "../utils";
import CandidatesTable from "./CandidatesTable";
import "./VerifySuspensionStep.css";

const columnHelper = createColumnHelper<CandidateAppointment>();

const getColumns = ([firstHeader, ...otherHeaders]: string[]) =>
  [
    {
      id: "select-col",
      header: ({ table }: HeaderContext<CandidateAppointment, string>) => (
        <Checkbox
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      ),
      cell: ({ row }: CellContext<CandidateAppointment, string>) => (
        <Checkbox
          checked={row.getIsSelected()}
          onChange={row.getToggleSelectedHandler()}
          disabled={!row.getCanSelect()}
        />
      ),
    },
    columnHelper.accessor(`appointment_display_data.${firstHeader}`, {
      header: firstHeader,
      cell: (info) =>
        info.row.original.notified ? (
          <Tooltip text="Cita suspendida: este paciente ya fue contactado.">
            <InlineIcon icon="uil:padlock" /> {info.getValue()}
          </Tooltip>
        ) : !info.row.original.has_phone ? (
          <Tooltip text="Paciente incontactable: el número de teléfono proporcionado no es válido.">
            <InlineIcon icon="uil:android-phone-slash" /> {info.getValue()}
          </Tooltip>
        ) : (
          info.getValue()
        ),
      enableSorting: false,
    }),
    ...otherHeaders.map((key) =>
      columnHelper.accessor(`appointment_display_data.${key}`, {
        header: key,
        cell: (info) => info.getValue(),
        enableSorting: false,
      }),
    ),
  ] as ColumnDef<CandidateAppointment, string>[];

interface VerifyAndSendSuspensionStepProps {
  onSuccess: () => void;
}

const VerifyAndSendSuspensionStep = ({
  onSuccess,
}: VerifyAndSendSuspensionStepProps) => {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const { selectedProfessional, startDate, endDate, excludedCandidateIds } =
    useContext(NewSuspensionContext);

  if (!selectedProfessional) {
    throw new Error("No professional selected");
  }
  const newSuspensionDispatch = useContext(NewSuspensionDispatchContext);
  const dispatch = useDispatch();

  const {
    data: suspensionCandidatesData,
    error,
    isError,
    isFetching,
  } = useGetSuspensionCandidatesQuery({
    professionalId: selectedProfessional?.value,
    centerId: selectedProfessional?.centerId,
    startDate: formatISO(startDate),
    endDate: formatISO(endDate),
  });

  const selectableCandidates = useMemo(
    () =>
      suspensionCandidatesData?.filter(
        (candidate) => !candidate.notified && candidate.has_phone,
      ),
    [suspensionCandidatesData],
  );

  const selectedCandidates = useMemo(
    () =>
      selectableCandidates?.filter((candidate) => rowSelection[candidate.id]),
    [selectableCandidates, rowSelection],
  );
  const selectedCandidatesCount = selectedCandidates?.length ?? 0;

  useEffect(() => {
    const exclusionSet = new Set(excludedCandidateIds);
    setRowSelection(
      selectableCandidates
        ? Object.fromEntries(
            selectableCandidates.map((candidate) => [
              candidate.id,
              !exclusionSet.has(candidate.id),
            ]),
          )
        : {},
    );
  }, [selectableCandidates, excludedCandidateIds]);

  const pluralize = (words: string[], count: number) => {
    return words.map((word) => word + (count === 1 ? "" : "s")).join(" ");
  };
  const footerSuffix = ` (${selectedCandidatesCount} ${pluralize(["paciente", "seleccionado"], selectedCandidatesCount)})`;

  const handleSuccess = () => {
    if (!selectedCandidates) {
      dispatch(
        addToast({
          type: "error",
          message: "Hubo un problema con el sistema, reintenta más tarde.",
        }),
      );
      return;
    }
    newSuspensionDispatch({
      type: "SET_SUSPENSION_APPOINTMENTS",
      payload: {
        excludedCandidateIds: _.difference(
          (selectableCandidates ?? []).map((c) => c.id),
          (selectedCandidates ?? []).map((c) => c.id),
        ),
        selectedAppointments: selectedCandidates,
      },
    });
    onSuccess();
  };

  return (
    <div className="UploadSpreadsheetStep">
      <div className="UploadSpreadsheetStep__section">
        <h3 className="UploadSpreadsheetStep__title">Verificar pacientes</h3>
        <p className="UploadSpreadsheetStep__copy">
          Revisa el listado de pacientes de acuerdo a los criterios definidos en
          el paso anterior. En esta etapa, puedes desmarcar a los pacientes que
          no desees incluir en el envío de mensajes.
        </p>
      </div>
      <div className="VerifySuspension__main_row">
        <div className="VerifySuspension__section">
          <div className="VerifySuspension__card">
            <div className="VerifySuspension__card_info">
              <div className="VerifySuspension__card_info_title">
                <Icon icon="uil:calendar-alt" />
                <span>Fecha de inicio</span>
              </div>
              <span>{humanizeFullDate(startDate)}</span>
            </div>
            <div className="VerifySuspension__card_info">
              <div className="VerifySuspension__card_info_title">
                <Icon icon="uil:calendar-alt" />
                <span>Fecha de término</span>
              </div>
              <span>{humanizeFullDate(endDate)}</span>
            </div>
            <div className="VerifySuspension__card_info">
              <div className="VerifySuspension__card_info_title">
                <Icon icon="uil:user-square" />
                <span>Profesional</span>
              </div>
              <span>{selectedProfessional.label}</span>
            </div>
          </div>
          {isError ? (
            <div>{getErrorMessageForCode(error)}</div>
          ) : suspensionCandidatesData?.length === 0 ? (
            <div>No se encontraron citas para suspender</div>
          ) : (
            <div className="VerifySuspensionStep__table_wrapper">
              <CandidatesTable
                appointments={suspensionCandidatesData}
                columns={
                  suspensionCandidatesData?.length
                    ? getColumns(
                        Object.keys(
                          suspensionCandidatesData[0].appointment_display_data,
                        ),
                      )
                    : []
                }
                footerSuffix={footerSuffix}
                rowSelection={rowSelection}
                onRowSelectionChange={setRowSelection}
                enableRowSelection={(row) =>
                  !row.original.notified && row.original.has_phone
                }
                getRowId={(row) => row.id}
              />
              {isFetching && (
                <div className="VerifySuspensionStep__loading_popup">
                  <Loader size="3rem" />
                  <div className="VerifySuspensionStep__loading_popup__content">
                    <div className="VerifySuspensionStep__loading_popup__title">
                      Estamos cargando los pacientes desde tu agenda
                    </div>
                    <div className="VerifySuspensionStep__loading_popup__detail">
                      El proceso podría tardar hasta unos{" "}
                      <span className="VerifySuspensionStep__loading_popup__highlight">
                        5 minutos
                      </span>
                      .
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <Button onClick={handleSuccess} disabled={selectedCandidatesCount === 0}>
        Configurar mensajes
      </Button>
    </div>
  );
};

export default VerifyAndSendSuspensionStep;
