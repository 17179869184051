import { Icon } from "@iconify/react";
import { rankItem } from "@tanstack/match-sorter-utils";
import {
  ColumnDef,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  OnChangeFn,
  Row,
  RowSelectionState,
  useReactTable,
} from "@tanstack/react-table";
import classNames from "classnames";
import { useState } from "react";

import Skeleton from "components/Skeleton";
import Table from "components/atoms/Table";
import PaginationControls from "components/molecules/PaginationControls";

import "./CandidatesTable.css";

const fuzzyFilter: FilterFn<unknown> = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value);
  addMeta({
    itemRank,
  });
  return itemRank.passed;
};

type CandidatesTableProps<T> = {
  appointments: T[] | undefined;
  columns: ColumnDef<T, string>[];
  rowSelection?: RowSelectionState;
  footerSuffix?: string;
  getRowId?: (row: T) => string;
  onRowSelectionChange?: OnChangeFn<RowSelectionState>;
  enableRowSelection?: boolean | ((row: Row<T>) => boolean);
};

const CandidatesTable = <T extends object>({
  appointments,
  columns,
  rowSelection,
  footerSuffix,
  getRowId,
  onRowSelectionChange,
  enableRowSelection,
}: CandidatesTableProps<T>) => {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const table = useReactTable({
    data: appointments ?? [],
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowId,
    enableRowSelection,
    onRowSelectionChange,
    state: { pagination, rowSelection },
  });

  const { rows } = table.getRowModel();

  if (!appointments) {
    return (
      <div className="CandidatesTable CandidatesTable--loading">
        <Table>
          <thead>
            <tr>
              {Array.from({ length: 7 }).map((_, j) => (
                <th key={j}>
                  <div style={{ display: "flex" }}>
                    <Skeleton />
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Array.from({ length: 10 }).map((_, i) => (
              <tr key={i}>
                {Array.from({ length: 7 }).map((_, j) => (
                  <td key={j}>
                    <div style={{ display: "flex" }}>
                      <Skeleton />
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th colSpan={7}>
                <div className="CandidatesTable__pagination">
                  <Skeleton />
                  <Skeleton />
                </div>
              </th>
            </tr>
          </tfoot>
        </Table>
      </div>
    );
  }
  return (
    <div className="CandidatesTable">
      <Table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                  className={classNames({
                    CandidatesTable__sortable: header.column.getCanSort(),
                  })}
                >
                  <div className="CandidatesTable__header_wrapper">
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                    {header.column.getIsSorted() === "asc" && (
                      <Icon
                        className="CandidatesTable__sort_icon"
                        icon="uil:arrow-up"
                      />
                    )}
                    {header.column.getIsSorted() === "desc" && (
                      <Icon
                        className="CandidatesTable__sort_icon"
                        icon="uil:arrow-down"
                      />
                    )}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {rows.map((row) => (
            <tr
              className={classNames({
                "CandidatesTable__table_row--disabled": !row.getCanSelect(),
              })}
              key={row.id}
            >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <th colSpan={columns.length}>
              <div className="CandidatesTable__pagination">
                <span>
                  Mostrando{" "}
                  <strong>
                    {pagination.pageIndex * pagination.pageSize + 1}&ndash;
                    {Math.min(
                      (pagination.pageIndex + 1) * pagination.pageSize,
                      appointments.length,
                    )}
                  </strong>{" "}
                  de <strong>{appointments.length}</strong>
                  {footerSuffix}
                </span>
                <PaginationControls
                  pages={
                    1 +
                    Math.trunc((appointments.length - 1) / pagination.pageSize)
                  }
                  current={pagination.pageIndex + 1}
                  setCurrent={(newIndex) =>
                    setPagination({ ...pagination, pageIndex: newIndex - 1 })
                  }
                />
              </div>
            </th>
          </tr>
        </tfoot>
      </Table>
    </div>
  );
};

export default CandidatesTable;
