import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useSelector } from "react-redux";

import { issuesApi } from "feedback-api";
import { loginSelector } from "store/slices/login";

type ReportIssueVars = {
  issueType?: string;
  serviceName?: string;
  description: string;
  contactEmail?: string;
  blobsGetter: () => Promise<{
    mainContainerBlob: Blob | null;
    messagesContainerBlob: Blob | null;
  }>;
};

/**
 * Maximum size in bytes for the image files to attach.
 *
 * We allow sending up to 1000 KB in image binary data (500 KB per image), which
 * leaves 24 KB for HTTP headers and other form fields (assuming a 1 MB limit in
 * nginx). This should be more than sufficient— the typical size for the form is
 * around 300 bytes (maybe 400 once we attach contact info), thus leaving 23 KB
 * just for headers.
 *
 * This limit is a last resort to avoid losing the form data submitted by the
 * user. We should do whatever we can to reduce the file size before calling
 * this mutation.
 */
const MAX_SIZE_BYTES = 500 * 1024;

const useReportIssueMutation = () => {
  const { nombreUsuario, cuenta } = useSelector(loginSelector);
  return useMutation<string, unknown, ReportIssueVars>({
    mutationKey: ["issue"],
    mutationFn: async ({
      description,
      serviceName,
      issueType = "Bot se equivoca",
      contactEmail,
      blobsGetter,
    }) => {
      const { mainContainerBlob, messagesContainerBlob } = await blobsGetter();

      const chatFile =
        messagesContainerBlob && messagesContainerBlob.size <= MAX_SIZE_BYTES
          ? new File([messagesContainerBlob], "Captura del chat.jpg")
          : undefined;
      const screenFile =
        mainContainerBlob && mainContainerBlob.size <= MAX_SIZE_BYTES
          ? new File([mainContainerBlob], "Captura de la vista.jpg")
          : undefined;

      const { data } = await issuesApi.reportIssueV4IssuesPost(
        serviceName ?? "",
        issueType,
        window.location.href,
        description,
        contactEmail,
        chatFile,
        screenFile,
      );
      await axios<undefined>({
        method: "post",
        url: "https://eoyqyij8fcu0p4y.m.pipedream.net",
        data: {
          client: nombreUsuario,
          service: serviceName,
          description,
          feedback_username: cuenta,
          feedback_url: window.location.href,
        },
      });
      return data.ts;
    },
  });
};

export default useReportIssueMutation;
