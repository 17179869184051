import { Icon } from "@iconify/react";
import { HttpStatusCode, isAxiosError } from "axios";
import { useState } from "react";
import { Link } from "react-router-dom";

import useSuspensionsQuery from "api/hooks/useSuspensionsQuery";
import Button from "components/atoms/Button";
import Error403 from "components/pages/Error403";
import useAnalytics from "hooks/useAnalytics";

import GeneralConfigurationDrawer from "./GeneralConfigurationDrawer/GeneralConfigurationDrawer";
import "./Suspensions.css";
import SuspensionsTable from "./SuspensionsTable";

const Suspensions = () => {
  const { data, error, isLoading } = useSuspensionsQuery();
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const track = useAnalytics();

  if (
    isAxiosError(error) &&
    error.response?.status === HttpStatusCode.Forbidden
  ) {
    return <Error403 mensaje="No puedes ver esta página." />;
  }

  return (
    <>
      <div className="Suspensions">
        <div className="Suspensions__header">
          <h2 className="Suspensions__title">Suspensión de citas</h2>
          <div className="Suspensions__options">
            <Button
              variant="outline"
              onClick={() => setDrawerOpen(true)}
              disabled={isLoading}
            >
              <Icon icon="uil:setting" />
              Configuración
            </Button>
            <Link
              className="Suspensions__create"
              to="nueva"
              onClick={() => track("Suspensions", "header", "create")}
            >
              Crear nueva suspensión
            </Link>
          </div>
        </div>
        <div className="Suspensions__wrapper">
          <div className="Suspensions__content">
            <h3 className="Suspensions__table_title">
              Historial de suspensiones
            </h3>
            {data?.length === 0 ? (
              <>Aún no has creado ninguna suspensión.</>
            ) : (
              <SuspensionsTable suspensions={data} />
            )}
          </div>
        </div>
      </div>
      <GeneralConfigurationDrawer open={isDrawerOpen} setOpen={setDrawerOpen} />
    </>
  );
};

export default Suspensions;
