// TODO: move to ../../hooks/
import { useQuery } from "@tanstack/react-query";
import * as _ from "lodash-es";
import { useSelector } from "react-redux";

import { loginSelector } from "store/slices/login";

import { AlertType } from "../types/domain";

const alertTypes = [
  {
    id: "Equivocado: Entrega número correcto",
    name: "Número equivocado: envía corrección",
  },
  {
    id: "Número equivocado",
    name: "Número equivocado",
  },
  {
    id: "Paciente tiene pregunta o comentario",
    name: "Pregunta o comentario",
  },
  {
    id: "Paciente reagenda post confirmación",
    name: "Reagenda post confirmación",
  },
  {
    id: "Paciente cancela post confirmación",
    name: "Cancela post confirmación",
  },
  {
    id: "Paciente se arrepiente de cancelar su hora",
    name: "Se arrepiente de cancelar hora",
  },
  {
    id: "Mensaje post encuesta",
    name: "Mensaje post interacción",
  },
  {
    id: "Indica fallecimiento",
    name: "Indica fallecimiento",
  },
  {
    id: "Paciente quiere reagendar",
    name: "Quiere reagendar",
  },
  {
    id: "Derivación de examen o procedimiento",
    name: "Derivación",
  },
  {
    id: "Paciente quiere cambiar a telemedicina",
    name: "Paciente quiere cambiar a telemedicina",
  },
  {
    id: "Paciente cancela su cita",
    name: "Paciente cancela su cita",
  },
  {
    id: "Paciente envía imagen",
    name: "Paciente envía imagen",
  },
  {
    id: "Paciente no ha recibido el mensaje",
    name: "Paciente no ha recibido el mensaje",
  },
];

const useAlertTypesQuery = () => {
  const { nombreUsuario } = useSelector(loginSelector);
  return useQuery<AlertType[], unknown>({
    queryKey: ["alertTypes"],
    queryFn: async () => {
      let filteredAlertTypes = alertTypes;
      if (
        nombreUsuario !== "NucleoSalud" &&
        nombreUsuario !== "Sanasalud" &&
        nombreUsuario !== "Interclínica" &&
        nombreUsuario !== "Andes Salud Chillán" &&
        nombreUsuario !== "Andes Salud Concepción" &&
        nombreUsuario !== "Andes Salud El Loa" &&
        nombreUsuario !== "Andes Salud Puerto Montt" &&
        nombreUsuario !== "CleverSalud" &&
        nombreUsuario !== "Isamédica" &&
        nombreUsuario !== "Clínica Santa María" &&
        nombreUsuario !== "Santa Maria Derivaciones" &&
        nombreUsuario !== "Clínica del Sur"
      ) {
        filteredAlertTypes = filteredAlertTypes.filter(
          (t) => t.id !== "Derivación de examen o procedimiento",
        );
      }
      if (nombreUsuario !== "CEAPSI") {
        filteredAlertTypes = filteredAlertTypes.filter(
          (t) => t.id !== "Paciente quiere cambiar a telemedicina",
        );
      }
      if (nombreUsuario !== "Caminos IPS") {
        filteredAlertTypes = filteredAlertTypes.filter(
          (t) => t.id !== "Paciente cancela su cita",
        );
      }
      if (
        nombreUsuario !== "YohananTherapeutes" &&
        nombreUsuario !== "Clinyco" &&
        nombreUsuario !== "Andes Salud Chillán" &&
        nombreUsuario !== "Centauro"
      ) {
        filteredAlertTypes = filteredAlertTypes.filter(
          (t) => t.id !== "Paciente envía imagen",
        );
      }
      if (nombreUsuario !== "uandes") {
        filteredAlertTypes = filteredAlertTypes.filter(
          (t) => t.id !== "Paciente no ha recibido el mensaje",
        );
      }
      if (nombreUsuario === "Clínica Santa María") {
        filteredAlertTypes = filteredAlertTypes.filter(
          (t) =>
            t.id !== "Paciente cancela post confirmación" &&
            t.id !== "Paciente tiene pregunta o comentario" &&
            t.id !== "Mensaje post encuesta",
        );
      }

      return _.sortBy(filteredAlertTypes, "name");
    },
  });
};

export default useAlertTypesQuery;
