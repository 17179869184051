import { format } from "date-fns";
import { useEffect, useState } from "react";

import logo from "assets/images/logo-feedback.svg";
import message_example from "assets/images/message_example.png";

import "./MetaTechProvider.css";

// Declarar las propiedades en el objeto global para TypeScript
declare global {
  interface Window {
    fbAsyncInit: () => void;
  }

  interface FB {
    init: (config: { appId: string; version: string }) => void;
    login: (
      callback: (response: {
        status: string;
        authResponse?: { accessToken: string; userID: string };
      }) => void,
      options: {
        config_id: string;
        auth_type: string;
        response_type: string;
        override_default_response_type: boolean;
        extras: { sessionInfoVersion: number };
      },
    ) => void;
  }

  const FB: FB;
}

const MetaTechProvider = () => {
  const [fbInitialized, setFbInitialized] = useState(false); // Flag para verificar si FB.init() se ha llamado
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Flag para trackear el estado de login
  useEffect(() => {
    const loadFacebookSDK = () => {
      window.fbAsyncInit = function () {
        FB.init({
          appId: "856800462063644",
          version: "v18.0",
        });
        setFbInitialized(true); // Marcamos como inicializado
      };

      // Añadir el script del SDK de Facebook
      (function (d, s, id) {
        const fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        const js: HTMLScriptElement = d.createElement(s) as HTMLScriptElement; // Especificar que js es de tipo HTMLScriptElement
        js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode?.insertBefore(js, fjs);
      })(document, "script", "facebook-jssdk");
    };

    loadFacebookSDK();

    // Definir el listener para eventos de WhatsApp Embedded Signup
    const embeddedSignupInfoListener = (event: MessageEvent) => {
      if (!event.origin.endsWith("facebook.com")) return;
      try {
        const data = JSON.parse(event.data);
        if (data.type === "WA_EMBEDDED_SIGNUP") {
          if (data.event === "FINISH" || data.event === "FINISH_ONLY_WABA") {
            const { phone_number_id, waba_id } = data.data;
            console.log({ phone_number_id, waba_id });
          } else {
            const { current_step } = data.data;
            console.log(`User did not finish ESU. Last step: ${current_step}`);
          }
        }
      } catch {
        return;
      }
    };

    window.addEventListener("message", embeddedSignupInfoListener);

    return () => {
      window.removeEventListener("message", embeddedSignupInfoListener);
    };
  }, []);

  // Función para lanzar el Embedded Signup de Facebook
  const launchEmbeddedSignup = () => {
    if (!fbInitialized) {
      console.error("FB SDK no está listo aún."); // Mensaje de error si intentan hacer login antes de la inicialización
      return;
    }

    FB.login(
      (response) => {
        console.log(response);
        if (response.status === "connected") {
          setIsLoggedIn(true);
        }
      },
      {
        config_id: "556993226812536",
        auth_type: "rerequest",
        response_type: "code",
        override_default_response_type: true,
        extras: {
          sessionInfoVersion: 2,
        },
      },
    );
  };

  return (
    <div className="MetaTechProvider">
      <div className="MetaTechProvider__contenedor_logo">
        <img
          className="MetaTechProvider__logo"
          src={logo}
          alt="Logo Cero Feedback"
        />
      </div>
      <div className="MetaTechProvider__form">
        {isLoggedIn ? (
          <div className="MetaTechProvider__success">
            <p className="MetaTechProvider__success_text">
              Has iniciado sesión con éxito! Ahora nuestro equipo te contactará
              para ayudarte a crear y verificar tus templates, así podremos
              mandar mensajes como los siguientes:
            </p>
            <br />
            <img
              className="MetaTechProvider__success_image"
              src={message_example}
              alt="Success"
            />
          </div>
        ) : (
          <>
            <h1 className="MetaTechProvider__instruccion">
              Conecta tu cuenta de negocio con Cero para usar WhatsApp
            </h1>
            <p className="MetaTechProvider__detalle">
              Para continuar, inicia sesión con Facebook.
              <br />
              Esto nos permitirá configurar un número de WhatsApp para ti de
              forma segura.
            </p>
            <button
              onClick={launchEmbeddedSignup}
              className="FacebookLoginButton"
            >
              Conectar con Facebook
            </button>
          </>
        )}
      </div>
      <footer className="MetaTechProvider__footer">
        © Cero {format(Date.now(), "yyyy")}
      </footer>
    </div>
  );
};

export default MetaTechProvider;
