import linkifyHtml from "linkify-html";
import sanitize from "sanitize-html";

import { ChatAPIMessage } from "api/types/responses";
import { toInternalFullDate } from "utils/date";

const boldRule =
  /(?<![a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ])\*(?! )(.*?)(?<! )\*(?![a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ])/g;

const italicsRule =
  /(?<![a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ])_(?! )(.*?)(?<! )_(?![a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ\d])/g;

const strikethroughRule =
  /(?<![a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ])~(?! )(.*?)(?<! )~(?![a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ\d])/g;

const formatMessageLine = (text: string) => {
  const textWithTags = text
    .replace(italicsRule, `<em>$1</em>`)
    .replace(boldRule, `<strong>$1</strong>`)
    .replace(strikethroughRule, `<del>$1</del>`);

  // Sanitize and then linkify the text: in that order to avoid hidding <a> tags that someone might have written
  const textWithLinks = linkifyHtml(
    sanitize(textWithTags, {
      allowedTags: ["em", "strong", "del"],
      disallowedTagsMode: "escape",
    }),
    {
      target: "_blank",
    },
  );

  return (
    <span
      dangerouslySetInnerHTML={{
        __html: textWithLinks,
      }}
    />
  );
};

export const formatWhatsappMsg = (texto: string) => {
  const formattedMsg: JSX.Element[] = [];
  const lines: string[] = texto.split("\n");

  let isCurrListNumbered: boolean = false;
  let currList: JSX.Element | null = null;
  let olMatch: RegExpMatchArray | null = null;

  lines.forEach((line, i) => {
    // unordered lists
    if (line.startsWith("- ") || line.startsWith("* ")) {
      const li = (
        <li key={i}>{formatMessageLine(line.slice(1).trimStart())}</li>
      );

      if (currList === null || isCurrListNumbered) {
        currList = <ul key={i}>{[li]}</ul>;
        formattedMsg.push(currList);
        isCurrListNumbered = false;
      } else if (Array.isArray(currList.props.children)) {
        currList.props.children.push(li);
      }
    }

    // numbered lists
    else if ((olMatch = line.match(/^(\d+)\.\s/))) {
      const number = olMatch[1];
      const li = (
        <li key={i} value={number}>
          {formatMessageLine(line.replace(olMatch[0], ""))}
        </li>
      );

      if (currList === null || !isCurrListNumbered) {
        currList = <ol key={i}>{[li]}</ol>;
        formattedMsg.push(currList);
        isCurrListNumbered = true;
      } else {
        currList.props.children.push(li);
      }
    } else {
      currList = null;
      formattedMsg.push(<span key={i}>{formatMessageLine(line)}</span>);
      formattedMsg.push(<br key={`br_${i}`} />);
    }
  });

  if (formattedMsg[formattedMsg.length - 1].type === "br") {
    formattedMsg.pop();
  }

  return <>{formattedMsg}</>;
};

export const getSeenData = (message: ChatAPIMessage, visible: boolean) => {
  if (message.type !== "bot") {
    return;
  }

  const messages: string[] = [];
  if (visible) {
    if (message.timestamp) {
      messages.push(
        `Enviado ${toInternalFullDate(new Date(message.timestamp))}`,
      );
    }
    if (message.timestamp_single_check) {
      messages.push(
        `Canal ${toInternalFullDate(new Date(message.timestamp_single_check))}`,
      );
    }
    if (message.timestamp_double_check) {
      messages.push(
        `Recibido ${toInternalFullDate(new Date(message.timestamp_double_check))}`,
      );
    }
    if (message.timestamp_blue_double_check) {
      messages.push(
        `Leído ${toInternalFullDate(new Date(message.timestamp_blue_double_check))}`,
      );
    }
  }

  if (message.timestamp_blue_double_check)
    return {
      messages,
      suffix: "read",
      icon: "solar:check-read-linear",
    };
  else if (message.timestamp_double_check)
    return {
      messages,
      suffix: "",
      icon: "solar:check-read-linear",
    };
  else if (message.timestamp_single_check)
    return {
      messages,
      suffix: "",
      icon: "solar:unread-linear",
    };
  else
    return {
      messages,
      suffix: "",
      icon: "solar:clock-square-linear",
    };
};
