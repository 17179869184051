import { useQuery } from "@tanstack/react-query";

import { suspensionsApi } from "feedback-api";

const useGetProfessionalsDataQuery = (query: string = "") => {
  return useQuery({
    queryKey: ["professionalsData", query],
    queryFn: async ({ signal }) => {
      const { data } =
        await suspensionsApi.getProfessionalsDataV4SuspensionsProfessionalsGet(
          query,
          { signal },
        );
      return data.professionals_data;
    },
  });
};

export default useGetProfessionalsDataQuery;
