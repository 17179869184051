import { Icon } from "@iconify/react";
import classNames from "classnames";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import useAnswerHeadersQuery from "api/hooks/useAnswerHeadersQuery";
import useAnswersQuery from "api/hooks/useAnswersQuery";
import { tieneAccesoAReportes } from "helpers/permisos";
import useAnalytics from "hooks/useAnalytics";
import { encuestasSelector } from "store/slices/encuestas";
import { loginSelector } from "store/slices/login";
import { fijaScrollTabla, respuestasSelector } from "store/slices/respuestas";

import BotonActualizar from "../BotonActualizar";
import BuscadorRespuestas from "../BuscadorRespuestas";
import ResumenRespuestas from "../ResumenRespuestas";
import SelectorRangoFechas from "../SelectorRangoFechas";
import BodyTablaRespuestas from "./BodyTablaRespuestas";
import ExportadorRespuestas from "./ExportadorRespuestas";
import Filtros from "./Filtros";
import FooterTablaRespuestas from "./FooterTablaRespuestas";
import HeadTablaRespuestas from "./HeadTablaRespuestas";
import "./TablaRespuestas.css";

const respuestasPorPagina = 50;
const idsEncuestasAgendamiento = [509, 557, 577];

const TablaRespuestas = () => {
  const { isFetching } = useAnswersQuery();
  const { data: headers, isSuccess: isSuccessHeaders } =
    useAnswerHeadersQuery();
  const { idEncuestaSeleccionada } = useSelector(encuestasSelector);
  const { cuenta } = useSelector(loginSelector);
  const refContenedor = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const {
    respuestasVisibles: respuestas,
    tablaDestacada,
    scrollTabla,
  } = useSelector(respuestasSelector);
  const track = useAnalytics();

  const cargando = !respuestas || !isSuccessHeaders;
  const mostrarResumen =
    (!isSuccessHeaders ||
      !!headers.find((h) => h.tipo === "YESNO" || h.tipo === "COMPUTED")) &&
    !(
      idEncuestaSeleccionada &&
      idsEncuestasAgendamiento.includes(idEncuestaSeleccionada)
    );

  useEffect(() => {
    if (refContenedor.current) {
      refContenedor.current.scrollTop = scrollTabla;
    }
    const scrollFinal = refContenedor.current?.scrollTop || 0;
    return () => {
      dispatch(fijaScrollTabla(scrollFinal));
    };
  }, [dispatch, scrollTabla]);

  useEffect(() => track("Feedback", "Respuestas", "index"), [track]);

  return (
    <div className="TablaRespuestas">
      <div className="TablaRespuestas__superior">
        <h1 className="TablaRespuestas__titulo">
          Respuestas
          <BotonActualizar />
        </h1>
        <SelectorRangoFechas />
        <div className="TablaRespuestas__herramientas">
          <BuscadorRespuestas cargando={cargando} />
          {tieneAccesoAReportes(cuenta) && (
            <ExportadorRespuestas cargando={cargando} />
          )}
        </div>
      </div>
      <div
        className={classNames({
          TablaRespuestas__contenedor: true,
          "TablaRespuestas__contenedor--cargando": isFetching,
        })}
      >
        <Filtros />
        {mostrarResumen && <ResumenRespuestas cargando={cargando} />}
        <div
          className={classNames({
            TablaRespuestas__overlay: true,
            "TablaRespuestas__overlay--activo": tablaDestacada,
          })}
        >
          <div className="TablaRespuestas__contenido_overlay">
            <Icon icon="mdi:download-outline" />
          </div>
        </div>
        <div className="TablaRespuestas__contenedor_central">
          <div
            className={classNames({
              TablaRespuestas__contenedor_tabla: true,
              "TablaRespuestas__contenedor_tabla--extendido": !mostrarResumen,
            })}
            ref={refContenedor}
          >
            <table className="TablaRespuestas__tabla">
              <HeadTablaRespuestas />
              <BodyTablaRespuestas respuestasPorPagina={respuestasPorPagina} />
            </table>
          </div>
        </div>
        <FooterTablaRespuestas
          cargando={cargando}
          respuestasPorPagina={respuestasPorPagina}
          totalRespuestas={respuestas ? respuestas.length : 0}
        />
      </div>
    </div>
  );
};

export default TablaRespuestas;
