import { captureException } from "@sentry/react";
import { isAxiosError } from "axios";
import { format } from "date-fns";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import useCampaignLimitsQuery from "api/hooks/useCampaignLimitsQuery";
import useConfigFieldsQuery from "api/hooks/useConfigFieldsQuery";
import useCreateCampaignMutation from "api/hooks/useCreateCampaignMutation";
import useProcessSpreadsheetMutation, {
  MaxRowsExceededError,
} from "api/hooks/useProcessSpreadsheetMutation";
import Stepper from "components/molecules/Stepper";
import { CampaignConfig } from "feedback-api";
import { addToast } from "store/slices/toasts";

import AssignFieldsStep from "./AssignFieldsStep";
import ConfigureCampaignStep from "./ConfigureCampaignStep";
import { DATETIME_LOCAL_FORMAT } from "./ConfigureCampaignStep/ConfigureCampaignStep";
import "./NewCampaign.css";
import UploadSpreadsheetStep from "./UploadSpreadsheetStep";

const UPLOAD_SPREADSHEET_STEP = 1;
const ASSIGN_FIELDS_STEP = 2;
const CONFIGURE_CAMPAIGN_STEP = 3;
const STEPS = ["Subir planilla", "Asignar campos", "Configurar campaña"];

type NewCampaignStep =
  | typeof UPLOAD_SPREADSHEET_STEP
  | typeof ASSIGN_FIELDS_STEP
  | typeof CONFIGURE_CAMPAIGN_STEP;

const NewCampaign = () => {
  const ref = useRef<HTMLDivElement>(null);
  const {
    data: limits,
    isPending: isLimitsPending,
    isError: isLimitsError,
  } = useCampaignLimitsQuery();

  const maxRows = useMemo(() => {
    if (isLimitsPending) {
      return NaN;
    }
    if (isLimitsError) {
      return Infinity;
    }
    return Math.min(
      limits.max_campaign_size,
      limits.max_monthly_interactions - limits.monthly_interactions,
      limits.max_lifetime_interactions - limits.lifetime_interactions,
    );
  }, [isLimitsPending, isLimitsError, limits]);

  const { data: campaignConfigFields, error: campaignConfigFieldsError } =
    useConfigFieldsQuery("create_campaign");

  const {
    mutate: uploadSpreadsheet,
    data: spreadsheetData,
    error: spreadsheetError,
    isSuccess: isProcessingSuccess,
    isPending: isProcessingSpreadsheet,
    reset,
  } = useProcessSpreadsheetMutation(maxRows);

  const { mutate: createCampaign, isPending: isCreatingCampaign } =
    useCreateCampaignMutation();

  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState<NewCampaignStep>(
    UPLOAD_SPREADSHEET_STEP,
  );
  const [selectedFile, setSelectedFile] = useState<File>();
  const [campaignConfig, setCampaignConfig] = useState<CampaignConfig>({
    name: "",
    start: format(Date.now(), DATETIME_LOCAL_FORMAT),
    file_id: "",
  });
  const [fieldMapping, setFieldMapping] = useState<Record<string, string[]>>();

  const navigate = useNavigate();

  const setStep = (step: number) => {
    ref.current?.parentElement?.scrollTo({ top: 0 });
    if (enabledSteps.includes(step as NewCampaignStep)) {
      setCurrentStep(step as NewCampaignStep);
    }
  };

  const handleAcceptedFiles = async (files: File[]) => {
    if (files.length === 0) {
      setSelectedFile(undefined);
      setFieldMapping(undefined);
      reset();
      return;
    }
    const file = files[0];
    setSelectedFile(file);
    uploadSpreadsheet(file);
  };

  const errorMessage = useMemo(() => {
    if (isProcessingSpreadsheet || !spreadsheetError) {
      return undefined;
    }
    if (spreadsheetError instanceof MaxRowsExceededError) {
      return `Tu archivo excede el límite de ${spreadsheetError.maxRows} pacientes permitidos, ya que contiene un total de ${spreadsheetError.submittedRows} pacientes.`;
    }
    if (isAxiosError(spreadsheetError)) {
      switch (spreadsheetError.response?.status) {
        case 413:
          return "El archivo es demasiado grande. Por favor divide el contenido en archivos más pequeños e intenta nuevamente.";
        case 415:
          return "El archivo tiene un formato no soportado. Por favor usa un formato de hoja de cálculo indicado arriba.";
        case 422:
          return "El archivo no tiene filas.";
      }
      captureException(spreadsheetError);
      setSelectedFile(undefined);
      return "Ocurrió un problema inesperado.";
    }
  }, [isProcessingSpreadsheet, spreadsheetError]);

  const enabledSteps = useMemo(() => {
    if (!spreadsheetData) {
      return [UPLOAD_SPREADSHEET_STEP];
    }
    if (!fieldMapping) {
      return [UPLOAD_SPREADSHEET_STEP, ASSIGN_FIELDS_STEP];
    }
    return [
      UPLOAD_SPREADSHEET_STEP,
      ASSIGN_FIELDS_STEP,
      CONFIGURE_CAMPAIGN_STEP,
    ];
  }, [spreadsheetData, fieldMapping]);

  const handleConfirmCreate = async () => {
    if (!spreadsheetData || !fieldMapping || !campaignConfig) {
      return;
    }
    createCampaign(
      {
        records: spreadsheetData.records,
        mapping: fieldMapping,
        config: campaignConfig,
      },
      {
        onSuccess: () => {
          dispatch(
            addToast({
              message: "¡La campaña fue creada exitosamente!",
              type: "success",
              durationMs: 5000,
            }),
          );
          navigate("/listas-espera");
        },
        onError: (error) => {
          captureException(error);
          dispatch(
            addToast({
              message: "¡Ups! Algo salió mal. Intenta de nuevo más tarde.",
              type: "error",
            }),
          );
        },
      },
    );
  };

  useEffect(() => {
    if (campaignConfigFieldsError) {
      captureException(campaignConfigFieldsError);
      dispatch(
        addToast({
          message: "¡Ups! Algo salió mal. Intenta de nuevo más tarde.",
          type: "error",
        }),
      );
    }
  }, [campaignConfigFieldsError, dispatch]);

  return (
    <div className="NewCampaign" ref={ref}>
      <h2 className="NewCampaign__title">Crear una nueva campaña</h2>
      <div className="NewCampaign__content">
        <Stepper
          currentStep={currentStep}
          enabledSteps={enabledSteps}
          onClick={setStep}
          steps={STEPS}
        />
        {currentStep === UPLOAD_SPREADSHEET_STEP && (
          <UploadSpreadsheetStep
            selectedFile={isProcessingSuccess ? selectedFile : undefined}
            isLoading={isProcessingSpreadsheet}
            handleAccepted={handleAcceptedFiles}
            error={errorMessage}
            onNext={() => setStep(ASSIGN_FIELDS_STEP)}
          />
        )}
        {currentStep === ASSIGN_FIELDS_STEP && spreadsheetData && (
          <AssignFieldsStep
            spreadsheetData={spreadsheetData}
            previousState={fieldMapping}
            onChange={setFieldMapping}
            onNext={() => setStep(CONFIGURE_CAMPAIGN_STEP)}
          />
        )}
        {currentStep === CONFIGURE_CAMPAIGN_STEP &&
          spreadsheetData &&
          campaignConfigFields && (
            <ConfigureCampaignStep
              isLoading={isCreatingCampaign}
              campaignName={campaignConfig.name}
              campaignStart={campaignConfig.start}
              spreadsheetData={spreadsheetData}
              fieldMapping={fieldMapping}
              campaignConfigFields={campaignConfigFields}
              setCampaignConfig={setCampaignConfig}
              onCreateCampaign={handleConfirmCreate}
            />
          )}
      </div>
    </div>
  );
};

export default NewCampaign;
