import { Icon } from "@iconify/react";

import useAnswerMediaQuery from "api/hooks/useChatAnswerMediaQuery";
import Loader from "components/atoms/Loader";

interface AttachmentMessageProps {
  answerId: number;
}

const AttachmentMessage = ({ answerId }: AttachmentMessageProps) => {
  const { data, isPending } = useAnswerMediaQuery(answerId);

  if (isPending) {
    return <Loader />;
  }
  return (
    <div className="AttachmentMessage">
      <a
        target="_blank"
        rel="noreferrer noopener"
        className="AttachmentMessage__file_link"
        href={data?.url}
      >
        <div className="AttachmentMessage__pdf_icon">PDF</div>
        <div className="AttachmentMessage__file_name">Descargar Archivo</div>
        <div className="AttachmentMessage__link_icon">
          <Icon icon="mdi:arrow-down-bold" />
        </div>
      </a>
    </div>
  );
};

export default AttachmentMessage;
