import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { captureException, captureMessage, setContext } from "@sentry/react";
import { formatISO9075, parse } from "date-fns";
import { es } from "date-fns/locale";

import { Respuesta } from "api/types/domain";
import { AnswersAPIResponse } from "api/types/responses";
import { getTags } from "helpers/tags";
import { obtenerTagsCalculados } from "helpers/tagsCalculados";
import { normalizar } from "utils/normalize";

import { RootState } from ".";
import { CANAL_HEADER_NAME } from "./encuestas";

const funcionFiltro = (
  r: Respuesta,
  nombreHeader: string,
  terminoNormalizado: string,
  idEncuesta: number,
  calceExacto = false,
) => {
  const tagCalculado = obtenerTagsCalculados(idEncuesta)?.find(
    (t) => t.nombre === nombreHeader,
  );
  if (tagCalculado) {
    const tagData = tagCalculado.f(r);
    const tagsEnDiccionario = getTags(tagData?.tag ?? "");
    if (tagsEnDiccionario) {
      return tagsEnDiccionario.some((tag) =>
        normalizar(tag.id).includes(terminoNormalizado),
      );
    } else if (!isNaN((tagData?.tag ?? "") as unknown as number)) {
      return normalizar(tagData?.tag ?? "").includes(terminoNormalizado);
    } else {
      return normalizar(tagData?.text ?? "").includes(terminoNormalizado);
    }
  }
  if (nombreHeader === "sucursal_name") {
    return r.respuestaNormalizada[nombreHeader] === terminoNormalizado;
  }
  if (calceExacto) {
    return r.respuestaNormalizada[nombreHeader] === terminoNormalizado;
  }
  return r.respuestaNormalizada[nombreHeader].includes(terminoNormalizado);
};

const ordenarPorFechaCita =
  (orden: "ASC" | "DESC" = "ASC") =>
  (r1: Respuesta, r2: Respuesta) => {
    try {
      const propHora = r1.time
        ? "time"
        : r1.time_1
          ? "time_1"
          : "time_truncated";
      const propFecha = r1.time || r1.time_truncated ? "date" : "date_1";
      const formato = r1[propHora].includes("M")
        ? "d 'de' MMMM h:m a"
        : "d 'de' MMMM H:m";
      const fecha1 = parse(
        `${r1[propFecha]} ${r1[propHora]}`,
        formato,
        new Date(),
        { locale: es },
      );
      const fecha2 = parse(
        `${r2[propFecha]} ${r2[propHora]}`,
        formato,
        new Date(),
        { locale: es },
      );
      return orden === "ASC"
        ? fecha1 > fecha2
          ? 1
          : -1
        : fecha1 < fecha2
          ? 1
          : -1;
    } catch (e) {
      captureException(e);
      return 1;
    }
  };

const funcionDeOrdenamiento = (
  header: string,
  orden: "ASC" | "DESC" = "ASC",
) => {
  if (header.includes("time") || header.includes("date")) {
    return ordenarPorFechaCita(orden);
  }
  return orden === "ASC"
    ? (r1: Respuesta, r2: Respuesta) =>
        r1.respuestaNormalizada[header] < r2.respuestaNormalizada[header]
          ? -1
          : 1
    : (r1: Respuesta, r2: Respuesta) =>
        r1.respuestaNormalizada[header] > r2.respuestaNormalizada[header]
          ? -1
          : 1;
};

export type Categoria = {
  propiedad: string;
  esTag: boolean;
  niveles: Respuesta[keyof Respuesta][];
};

type GlobalFilter = {
  headers: "*";
  busqueda: string;
  descripcion: string;
  oculto?: undefined;
  f: (r: Respuesta) => boolean;
};

type ColumnFilter = {
  headers: string[];
  busqueda: string[];
  terminosNormalizados: string[];
  nombresHeaders: string[];
  descripcion: string;
  oculto?: boolean;
  f: (r: Respuesta) => boolean;
};

export interface RespuestasState {
  abrirAppWhatsapp: boolean;

  scrollTabla: number;

  respuestas?: Respuesta[];
  respuestasVisibles?: Respuesta[];
  categorias: Categoria[];
  filtros: (GlobalFilter | ColumnFilter)[];
  pagina: number;
  fechaActualizacion: number;
  fechaInicio: number;
  fechaTermino: number;
  busqueda: string;
  nombreEncuestaFiltrada?: string;
  indiceRespuestaSeleccionada?: number;
  ordenHeader?: string;
  orden: "ASC" | "DESC";

  columnaDestacadaFija: boolean;
  columnaDestacada?: number;

  tablaDestacada: boolean;
}

const sliceRespuestas = createSlice({
  name: "respuestas",
  initialState: {
    fechaInicio: Date.now(),
    fechaTermino: Date.now(),
    busqueda: "",
    orden: "ASC",
    pagina: 1,
    filtros: [],
    categorias: [],
    columnaDestacada: undefined,
    columnaDestacadaFija: false,
    tablaDestacada: false,
    nombreEncuestaFiltrada: undefined,
    scrollTabla: 0,
    fechaActualizacion: Date.now(),
    abrirAppWhatsapp: false,
  } as RespuestasState,
  reducers: {
    fijaAbrirAppWhatsapp(state, action: PayloadAction<boolean>) {
      state.abrirAppWhatsapp = action.payload;
    },
    fijaScrollTabla(state, action: PayloadAction<number>) {
      state.scrollTabla = action.payload;
    },
    limpiaRespuestas(state) {
      state.respuestas = undefined;
      state.respuestasVisibles = undefined;
      state.indiceRespuestaSeleccionada = undefined;
    },
    guardaRespuestas(
      state,
      action: PayloadAction<{
        jsonRespuestas: AnswersAPIResponse;
        idEncuesta: number;
      }>,
    ) {
      const { jsonRespuestas, idEncuesta } = action.payload;
      const respuestas: Respuesta[] = jsonRespuestas.data
        .filter((r) => r.started === "True")
        .map((r) => {
          const canal = !r.is_unreachable.whatsapp
            ? { icon: "whatsapp", label: "Whatsapp" }
            : !r.is_unreachable.phone
              ? { icon: "phone", label: "Teléfono" }
              : { icon: "cellphone-off", label: "No pudo ser contactado" };

          /** A concatenation of normalized string values and tag IDs/names from r. */
          const respuestaString = Object.values(r).reduce<string>((prev, v) => {
            let slug = "";
            if (typeof v === "string") {
              slug = normalizar(v);
            } else if (v?.tag) {
              slug = normalizar(
                getTags(v.tag)
                  .map((v) => v.id)
                  .join() || v.tag,
              );
            }
            return prev + slug;
          }, "");
          /** A mapping of keys to normalized string values, tag IDs/names and icon labels from r. */
          const respuestaNormalizada = Object.entries(r).reduce<{
            [k: string]: string;
          }>(
            (prev, [k, v]) => {
              if (typeof v === "string") {
                prev[k] = normalizar(v);
              } else if (v?.tag || v?.tag === "") {
                prev[k] = normalizar(
                  getTags(v.tag)
                    .map((v) => v.id)
                    .join() || v.tag,
                );
              }
              return prev;
            },
            { [CANAL_HEADER_NAME]: normalizar(canal.label) },
          );

          return {
            ...r,
            [CANAL_HEADER_NAME]: canal,
            respuestaString,
            respuestaNormalizada,
          };
        })
        .reverse();

      let categorias: Categoria[] = [];
      if (respuestas.length > 0) {
        categorias = Object.keys(respuestas[0]).map((propiedad) =>
          respuestas[0][propiedad]?.tag !== undefined
            ? {
                propiedad,
                esTag: true,
                niveles: Array.from(
                  new Set(respuestas.map((r) => r[propiedad].tag)),
                ).sort((x, y) => (x > y ? 1 : -1)),
              }
            : respuestas[0][propiedad]?.icon
              ? {
                  propiedad,
                  esTag: false,
                  niveles: Array.from(
                    new Set(respuestas.map((r) => r[propiedad].label)),
                  ).sort((x, y) => (x > y ? 1 : -1)),
                }
              : {
                  propiedad,
                  esTag: false,
                  niveles: Array.from(
                    new Set(respuestas.map((r) => r[propiedad])),
                  ).sort((x, y) => (x > y ? 1 : -1)),
                },
        );
        const tagsCalculados = obtenerTagsCalculados(idEncuesta);
        if (tagsCalculados) {
          categorias = categorias.concat(
            tagsCalculados.map((tag) => {
              if (tag.f(respuestas[0]) === undefined) {
                setContext("tagsCalculados related info", {
                  tagCalculado: tag,
                  idEncuesta,
                  args: [respuestas[0]],
                });
                captureMessage(
                  "tagCalculado is probably out of date",
                  "warning",
                );
              }
              return {
                propiedad: tag.nombre,
                esTag: true,
                niveles: Array.from(
                  new Set(respuestas.map((r) => tag.f(r)?.tag ?? "")),
                ).sort((x, y) => (x > y ? 1 : -1)),
              };
            }),
          );
        }
      }

      state.categorias = categorias;
      state.respuestas = respuestas;
      state.respuestasVisibles = respuestas.filter((r) =>
        state.filtros.reduce((res, { f }) => res && f(r), true),
      );
      state.indiceRespuestaSeleccionada = undefined;
      state.pagina = 1;
      state.fechaActualizacion = Date.now();
    },
    guardaRangoFechas(state, action: PayloadAction<[number, number]>) {
      const [fechaInicio, fechaTermino] = action.payload;
      state.fechaInicio = fechaInicio;
      state.fechaTermino = fechaTermino;
    },
    buscaEsto(state, action: PayloadAction<string>) {
      const terminoNormalizado = normalizar(action.payload);
      state.busqueda = action.payload;
      state.pagina = 1;
      const indiceFiltroGlobal = state.filtros.findIndex(
        (f) => f.headers === "*",
      );
      const filtro = {
        headers: "*",
        busqueda: action.payload,
        descripcion: `Filtro global: "${action.payload}"`,
        f: (r: Respuesta) => r.respuestaString.includes(terminoNormalizado),
      } as const;
      if (indiceFiltroGlobal >= 0) {
        if (terminoNormalizado.length > 0) {
          state.filtros[indiceFiltroGlobal] = filtro;
        } else {
          state.filtros.splice(indiceFiltroGlobal, 1);
        }
      } else if (terminoNormalizado.length > 0) {
        state.filtros.push(filtro);
      }
      state.respuestasVisibles = state.respuestas
        ? state.respuestas.filter((r) =>
            state.filtros.reduce((res, { f }) => res && f(r), true),
          )
        : [];
      state.indiceRespuestaSeleccionada = undefined;
    },
    agregaFiltro(
      state,
      action: PayloadAction<{
        busqueda: string;
        nombreHeader: string;
        textoHeader: string;
        idEncuesta: number;
        opciones?: {
          filtroImplicito?: boolean;
          calceExacto?: boolean;
          mismaColumna?: boolean;
          titulo?: string;
          temporal?: boolean;
        };
      }>,
    ) {
      const { busqueda, nombreHeader, textoHeader, idEncuesta, opciones } =
        action.payload;
      const { filtroImplicito, calceExacto, titulo, temporal, mismaColumna } =
        opciones || {};
      const terminoNormalizado = normalizar(busqueda);
      const filtro = {
        headers: [nombreHeader],
        nombresHeaders: [textoHeader],
        busqueda: [busqueda],
        terminosNormalizados: [terminoNormalizado],
        descripcion: `"${busqueda}" en ${textoHeader}`,
        oculto: filtroImplicito,
        temporal,
        f: (r: Respuesta) =>
          funcionFiltro(
            r,
            nombreHeader,
            terminoNormalizado,
            idEncuesta,
            calceExacto,
          ),
      };
      const indiceFiltro = state.filtros.findIndex(
        (f) =>
          Array.isArray(f.headers) &&
          f.headers.every((h) => h === nombreHeader),
      );
      if (indiceFiltro >= 0) {
        const filtroExistente = state.filtros[indiceFiltro] as ColumnFilter;
        if (mismaColumna) {
          const indiceTerminoExistente = filtroExistente.busqueda.findIndex(
            (t) => t === busqueda,
          );
          if (indiceTerminoExistente < 0) {
            const nombresHeaders = [
              ...filtroExistente.nombresHeaders,
              textoHeader,
            ];
            const terminosNormalizados = [
              ...filtroExistente.terminosNormalizados,
              terminoNormalizado,
            ];
            const headers = [...filtroExistente.headers, nombreHeader];
            state.filtros[indiceFiltro] = {
              headers: [...filtroExistente.headers, nombreHeader],
              busqueda: [...filtroExistente.busqueda, busqueda],
              terminosNormalizados,
              nombresHeaders,
              descripcion: nombresHeaders
                .map((h, i) => `"${busqueda[i]}" en ${h}`)
                .join(" o "),
              f: (r) =>
                headers.some((h, i) =>
                  funcionFiltro(r, h, terminosNormalizados[i], idEncuesta),
                ),
            };
          } else if (
            filtroExistente.busqueda.length === 1 &&
            filtroExistente.busqueda[0] === busqueda
          ) {
            state.filtros.splice(indiceFiltro, 1);
          } else {
            filtroExistente.nombresHeaders.splice(indiceTerminoExistente, 1);
            filtroExistente.terminosNormalizados.splice(
              indiceTerminoExistente,
              1,
            );
            filtroExistente.headers.splice(indiceTerminoExistente, 1);
            filtroExistente.busqueda.splice(indiceTerminoExistente, 1);
            filtroExistente.descripcion = filtroExistente.nombresHeaders
              .map((h, i) => `"${busqueda[i]}" en ${h}`)
              .join(" o ");
            filtroExistente.f = (r) =>
              filtroExistente.headers.some((h, i) =>
                funcionFiltro(
                  r,
                  h,
                  filtroExistente.terminosNormalizados[i],
                  idEncuesta,
                ),
              );
          }
        } else if (
          terminoNormalizado.length > 0 &&
          filtroExistente.busqueda[0] !== busqueda
        ) {
          state.filtros[indiceFiltro] = filtro;
        } else if (!filtroImplicito) {
          state.filtros.splice(indiceFiltro, 1);
        }
      } else if (busqueda !== "") {
        state.filtros.push(filtro);
      }
      if (filtroImplicito) {
        state.nombreEncuestaFiltrada = titulo;
      }
      state.respuestasVisibles = state.respuestas?.filter((r) =>
        state.filtros.reduce((res, { f }) => res && f(r), true),
      );
      state.indiceRespuestaSeleccionada = undefined;
      state.pagina = 1;
    },
    combinaFiltros(state, action: PayloadAction<[number, number]>) {
      const [i, j] = action.payload;
      if (i === j) {
        return;
      }

      const filtroi = state.filtros[i];
      const filtroj = state.filtros[j];

      if (filtroi.headers === "*" || filtroj.headers === "*") {
        // We only allow joining column filters for now.
        return;
      }

      const headers = [...filtroj.headers, ...filtroi.headers];
      const busqueda = [...filtroj.busqueda, ...filtroi.busqueda];
      const nombresHeaders = [
        ...filtroj.nombresHeaders,
        ...filtroi.nombresHeaders,
      ];
      const terminosNormalizados = [
        ...filtroj.terminosNormalizados,
        ...filtroi.terminosNormalizados,
      ];
      const fi = filtroi.f;
      const fj = filtroj.f;

      state.filtros[j] = {
        headers,
        busqueda,
        nombresHeaders,
        terminosNormalizados,
        descripcion: nombresHeaders
          .map((h, i) => `"${busqueda[i]}" en ${h}`)
          .join(" o "),
        // We reference the functions themselves; the ColumnFilter objects will
        // no longer be accessible in future calls to combinaFiltros.
        f: (r) => fi(r) || fj(r),
      };
      state.filtros.splice(i, 1);
      state.respuestasVisibles = state.respuestas?.filter((r) =>
        state.filtros.reduce((res, { f }) => res && f(r), true),
      );
      state.indiceRespuestaSeleccionada = undefined;
    },
    remueveFiltro(state, action: PayloadAction<number>) {
      const indiceFiltro = action.payload;
      const indiceFiltroGlobal = state.filtros.findIndex(
        (f) => f.headers === "*",
      );
      if (indiceFiltro === indiceFiltroGlobal) {
        state.busqueda = "";
      }
      state.filtros.splice(indiceFiltro, 1);
      state.respuestasVisibles = state.respuestas?.filter((r) =>
        state.filtros.reduce((res, { f }) => res && f(r), true),
      );
      state.indiceRespuestaSeleccionada = undefined;
    },
    limpiaFiltros(state) {
      state.filtros = [];
      state.respuestasVisibles = state.respuestas;
      state.indiceRespuestaSeleccionada = undefined;
      state.nombreEncuestaFiltrada = undefined;
      state.busqueda = "";
    },
    guardaIdRespuesta(state, action: PayloadAction<number>) {
      const indice = action.payload;
      state.indiceRespuestaSeleccionada = indice;
    },
    ordenaRespuestas(
      state,
      action: PayloadAction<{ header: string; idEncuesta: number }>,
    ) {
      const { header, idEncuesta } = action.payload;
      state.ordenHeader = header;
      const tagCalculado = obtenerTagsCalculados(idEncuesta)?.find(
        (t) => t.nombre === header,
      );

      if (state.orden === "ASC") {
        state.orden = "DESC";
      } else {
        state.orden = "ASC";
      }

      let sortFn: (a: Respuesta, b: Respuesta) => number;
      if (tagCalculado) {
        const getTag = (r: Respuesta) =>
          normalizar(tagCalculado.f(r)?.tag ?? "");
        if (state.orden === "ASC") {
          sortFn = (r1, r2) => (getTag(r1) < getTag(r2) ? -1 : 1);
        } else {
          sortFn = (r1, r2) => (getTag(r1) > getTag(r2) ? -1 : 1);
        }
      } else {
        sortFn = funcionDeOrdenamiento(header, state.orden);
      }

      state.respuestas?.sort(sortFn);
      state.respuestasVisibles?.sort(sortFn);
      state.indiceRespuestaSeleccionada = undefined;
    },
    setPagina(state, action: PayloadAction<number>) {
      state.pagina = action.payload;
    },
    destacaColumna(state, action: PayloadAction<number>) {
      if (!state.columnaDestacadaFija) {
        state.columnaDestacada = action.payload;
      }
    },
    yaNoDestaquesColumna(state) {
      if (!state.columnaDestacadaFija) {
        state.columnaDestacada = undefined;
      }
    },
    fijaColumna(state, action: PayloadAction<boolean>) {
      state.columnaDestacadaFija = action.payload;
      if (!action.payload) {
        state.columnaDestacada = undefined;
      }
    },
    fijaTablaDestacada(state, action: PayloadAction<boolean>) {
      state.tablaDestacada = action.payload;
    },
    agregaReaccionARespuesta(
      state,
      action: PayloadAction<{
        idUsuario: string | number;
        emoji: string;
        texto: string;
      }>,
    ) {
      if (!state.respuestas || !state.respuestasVisibles) {
        return;
      }

      const { idUsuario, emoji, texto } = action.payload;
      const nuevaReaccion = {
        created_at: formatISO9075(Date.now()),
        reaction_emoji: emoji,
        reaction_text: texto,
      };
      const matchesUser = (r: Respuesta) => r.user_id === Number(idUsuario);

      const respuestaVisible = state.respuestasVisibles.find(matchesUser);
      respuestaVisible?.reactions.push(nuevaReaccion);

      const respuesta = state.respuestas.find(matchesUser);
      respuesta?.reactions.push(nuevaReaccion);
    },
    eliminaReaccionDeRespuesta(
      state,
      action: PayloadAction<{ idUsuario: string; fecha: string }>,
    ) {
      if (!state.respuestas || !state.respuestasVisibles) {
        return;
      }

      const { idUsuario, fecha } = action.payload;
      const matchesUser = (r: Respuesta) => r.user_id === Number(idUsuario);
      const matchesDate = (r: Respuesta["reactions"][0]) =>
        r.created_at === fecha;

      const respuestaVisible = state.respuestasVisibles.find(matchesUser);
      if (respuestaVisible) {
        const reactionIndex = respuestaVisible.reactions.findIndex(matchesDate);
        respuestaVisible.reactions.splice(reactionIndex, reactionIndex);
      }

      const respuesta = state.respuestas.find(matchesUser);
      if (respuesta) {
        const reactionIndex = respuesta.reactions.findIndex(matchesDate);
        respuesta.reactions.splice(reactionIndex, reactionIndex);
      }
    },
  },
});

export const {
  limpiaRespuestas,
  guardaRespuestas,
  guardaRangoFechas,
  buscaEsto,
  guardaIdRespuesta,
  ordenaRespuestas,
  setPagina,
  agregaFiltro,
  remueveFiltro,
  combinaFiltros,
  destacaColumna,
  yaNoDestaquesColumna,
  fijaTablaDestacada,
  fijaColumna,
  limpiaFiltros,
  agregaReaccionARespuesta,
  eliminaReaccionDeRespuesta,
  fijaScrollTabla,
  fijaAbrirAppWhatsapp,
} = sliceRespuestas.actions;
export const respuestasSelector = (state: RootState) => state.respuestas;

export default sliceRespuestas.reducer;
