import { useIsFetching } from "@tanstack/react-query";
import { CSSProperties } from "react";

import useMetricsProgressQuery, {
  ProgressMetric,
} from "api/hooks/useMetricsProgressQuery";
import Loader from "components/atoms/Loader/Loader";

import { getMetricHexColor } from "../utils";
import "./ProgressDonut.css";

interface ProgressDonutProps {
  metric: ProgressMetric;
}

const ProgressDonut = ({ metric }: ProgressDonutProps) => {
  const { data, isPending } = useMetricsProgressQuery({ metric });
  const isFetchingMetrics = useIsFetching({ queryKey: ["metrics"] });

  if (isPending || isFetchingMetrics) {
    return <Loader />;
  }

  return (
    <div
      className="ProgressDonut"
      style={
        {
          "--donut-leftover": `${
            data?.fillPercentage ? data.fillPercentage + 0.5 : 0
          }`,
          "--donut-material": getMetricHexColor(metric),
        } as CSSProperties
      }
    >
      <h2 className="ProgressDonut__title">{data?.title}</h2>
      <figure className="ProgressDonut__figure">{data?.count}</figure>
      <p className="ProgressDonut__label">{data?.label}</p>
    </div>
  );
};

export default ProgressDonut;
