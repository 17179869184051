import { Icon, InlineIcon } from "@iconify/react";
import { captureException } from "@sentry/react";
import { isAxiosError } from "axios";
import classNames from "classnames";
import { useState, useCallback } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useDispatch, useSelector } from "react-redux";

import useReportIssueMutation from "api/hooks/useReportIssueMutation";
import logoCero from "assets/images/logo-cero.svg";
import Loader from "components/atoms/Loader";
import Select from "components/atoms/Select";
import { getBlobsFromChat } from "helpers/screenCapture";
import useAnalytics from "hooks/useAnalytics";
import useWhatsappLink from "hooks/useWhatsappLink";
import { encuestasSelector } from "store/slices/encuestas";
import { loginSelector } from "store/slices/login";
import {
  updateContactEmailCache,
  opcionesSelector,
} from "store/slices/opciones";
import {
  fijaAbrirAppWhatsapp,
  respuestasSelector,
} from "store/slices/respuestas";

import "./AccionesChat.css";

const obtenerSonrisa = () => {
  const opciones = ["😀", "😊", "😄", "😁", "😃", "😉", "🙂"];
  return opciones[Math.floor(Math.random() * opciones.length)];
};

interface AccionesChatProps {
  telefono: string;
  link: { tipo: string; url: string } | undefined;
}

const AccionesChat = ({ telefono, link }: AccionesChatProps) => {
  const {
    mutate,
    isPending: enviando,
    isSuccess: enviado,
    error: reportIssueError,
  } = useReportIssueMutation();

  const { nombreUsuario, cuenta } = useSelector(loginSelector);
  const [formularioVisible, setFormularioVisible] = useState(false);
  const { tipos, idEncuestaSeleccionada } = useSelector(encuestasSelector);
  const { contactEmailCache } = useSelector(opcionesSelector);
  const nombreEncuestaSeleccionada =
    tipos?.find(({ id }) => id === idEncuestaSeleccionada)?.nombre ?? "?";
  const [tipo, setTipo] = useState("Bot se equivoca");
  const [descripcion, setDescripcion] = useState("");
  const [contactEmail, setContactEmail] = useState(contactEmailCache || "");
  const dispatch = useDispatch();
  const track = useAnalytics();
  const { abrirAppWhatsapp } = useSelector(respuestasSelector);
  const [seleccionandoModoWhatsapp, setSeleccionandoModoWhatsapp] =
    useState(false);
  const whatsappLink = useWhatsappLink(telefono);

  const abrirChatEnWhatsapp = useCallback(() => {
    track("Feedback", "Chat", "abrirWhatsappWeb", { whatsappLink });
    window.open(whatsappLink, "_blank")?.focus();
  }, [track, whatsappLink]);

  const abrirAgenda = (url: string) => {
    track("Feedback", "Chat", "abrirAgenda", { link: url });
    window.open(url, "_blank")?.focus();
  };

  if (!nombreUsuario || !cuenta) {
    return;
  }

  const reportarProblema = async (e: React.FormEvent) => {
    e.preventDefault();
    track("Feedback", "Chat", "enviarReporteDeProblema", {
      nombreUsuario,
      cuenta,
      nombreEncuestaSeleccionada,
      tipo,
      descripcion,
    });
    mutate(
      {
        description: descripcion,
        serviceName: nombreEncuestaSeleccionada,
        issueType: tipo,
        contactEmail,
        blobsGetter: getBlobsFromChat,
      },
      {
        onSuccess: () => dispatch(updateContactEmailCache(contactEmail)),
        onError: (error) => captureException(error),
      },
    );
  };

  if (enviado) {
    return (
      <div className="AccionesChat__agradecimiento">
        <InlineIcon
          className="AccionesChat__icono_gracias"
          icon="mdi:robot-excited"
        />
        <p className="AccionesChat__gracias">
          ¡Hemos recibido tu reporte, gracias por ayudarnos a mejorar nuestro
          servicio! {obtenerSonrisa()}
        </p>
      </div>
    );
  }

  return (
    <div className="AccionesChat">
      <h2 className="AccionesChat__titulo">Acciones</h2>
      {formularioVisible ? (
        <form onSubmit={reportarProblema} className="AccionesChat__formulario">
          <button
            className="AccionesChat__boton_cerrar"
            onClick={() => {
              track("Feedback", "Chat", "cancelarReporteDeProblema");
              setFormularioVisible(false);
            }}
            title="Cancelar"
          >
            <InlineIcon icon="mdi:close" />
          </button>
          <label className="AccionesChat__label">Tipo de problema</label>
          <Select
            isDisabled={enviando}
            value={{ label: tipo, value: tipo }}
            options={[
              { label: "Bot se equivoca", value: "Bot se equivoca" },
              { label: "Paciente se molesta", value: "Paciente se molesta" },
              { label: "Otro", value: "Otro" },
            ]}
            onChange={(newValue) => newValue && setTipo(newValue.value)}
            required
          />
          <label htmlFor="report-description" className="AccionesChat__label">
            Descripción
          </label>
          <textarea
            id="report-description"
            autoFocus
            disabled={enviando}
            value={descripcion}
            onKeyUp={(e) => e.stopPropagation()}
            onChange={(e) => setDescripcion(e.target.value)}
            className="AccionesChat__textarea"
            placeholder="No dudes al contarnos cualquier anomalía que detectes, ¡nos ayudas a brindarte un mejor servicio!"
            required
          />
          <label htmlFor="report-contact" className="AccionesChat__label">
            E-mail de contacto
          </label>
          <input
            id="report-contact"
            className="AccionesChat__input"
            type="email"
            placeholder="Tu e-mail de contacto."
            value={contactEmail}
            onChange={(e) => setContactEmail(e.target.value)}
            required
          />
          <button
            disabled={enviando}
            className="AccionesChat__boton_enviar"
            title="Reportar"
          >
            {enviando ? (
              <>
                <Loader color="white" size="1rem" /> Enviando reporte...
              </>
            ) : (
              <>
                Reportar problema a{" "}
                <img
                  className="AccionesChat__logo_cero"
                  src={logoCero}
                  alt="logo Cero"
                />
              </>
            )}
          </button>
          {isAxiosError(reportIssueError) && <p>{reportIssueError.message}</p>}
        </form>
      ) : (
        <>
          <div className="AccionesChat__contenedor_boton_whatsapp">
            <OutsideClickHandler
              onOutsideClick={() => setSeleccionandoModoWhatsapp(false)}
            >
              <div
                className={classNames({
                  AccionesChat__modal_modo_whatsapp: true,
                  "AccionesChat__modal_modo_whatsapp--activo":
                    seleccionandoModoWhatsapp,
                })}
              >
                <button
                  className="AccionesChat__boton_modo_whatsapp"
                  onClick={() => {
                    setSeleccionandoModoWhatsapp(false);
                    dispatch(fijaAbrirAppWhatsapp(false));
                  }}
                >
                  Usar Whatsapp Web
                  <InlineIcon
                    icon="mdi:check"
                    // TODO(readability): use className instead of style
                    style={abrirAppWhatsapp ? { opacity: 0 } : undefined}
                  />
                </button>
                <button
                  className="AccionesChat__boton_modo_whatsapp"
                  onClick={() => {
                    setSeleccionandoModoWhatsapp(false);
                    dispatch(fijaAbrirAppWhatsapp(true));
                  }}
                >
                  Usar App de Whatsapp
                  <InlineIcon
                    icon="mdi:check"
                    // TODO(readability): use className instead of style
                    style={abrirAppWhatsapp ? undefined : { opacity: 0 }}
                  />
                </button>
              </div>
            </OutsideClickHandler>
            <button
              className="AccionesChat__boton AccionesChat__boton--whatsapp"
              onClick={abrirChatEnWhatsapp}
              title={
                abrirAppWhatsapp
                  ? "Abrir chat en Whatsapp"
                  : "Abrir chat en Whatsapp Web"
              }
            >
              <InlineIcon
                // TODO(readability): move font-size into AccionesChat__icono_boton
                style={{ fontSize: ".8rem" }}
                className="AccionesChat__icono_boton"
                icon="mdi:whatsapp"
              />
              Contactar por{" "}
              {abrirAppWhatsapp ? "App de Whatsapp" : "Whatsapp Web"}
            </button>
            <button
              className="AccionesChat__boton_selector_modo_whatsapp"
              onClick={() =>
                setSeleccionandoModoWhatsapp(!seleccionandoModoWhatsapp)
              }
            >
              <Icon icon="mdi:chevron-down" />
            </button>
          </div>
          {link && (
            <button
              className="AccionesChat__boton"
              onClick={() => abrirAgenda(link.url)}
              title={`Abrir chat en ${link.tipo}`}
            >
              <InlineIcon
                style={{ fontSize: ".8rem" }}
                className="AccionesChat__icono_boton"
                icon="mdi:arrow-top-right"
              />
              Ver cita en {link.tipo}
            </button>
          )}
          <button
            className="AccionesChat__boton"
            onClick={() => {
              track("Feedback", "Chat", "reportarProblema");
              setFormularioVisible(true);
            }}
            title="Reportar problema a CERO"
          >
            <InlineIcon
              style={{ fontSize: ".8rem" }}
              className="AccionesChat__icono_boton"
              icon="mdi:report"
            />
            Reportar problema a Cero
          </button>
        </>
      )}
    </div>
  );
};

export default AccionesChat;
