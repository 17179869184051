import "./Loader.css";

interface LoaderProps {
  size?: string | number;
  color?: string;
}

const Loader = ({
  color = "var(--color-principal)",
  size = "1.15rem",
}: LoaderProps) => {
  return (
    <svg
      className="Loader"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      stroke={color}
      strokeWidth="2"
    >
      <circle cx="8" cy="8" r="7" strokeOpacity="0.125" />
      <path d="M 8 15 A 7 7 0 1 1 15 8" strokeLinecap="round" />
    </svg>
  );
};

export default Loader;
