export const humanizeFullDate = (d: Date) =>
  `${d.toLocaleDateString("es-ES", { dateStyle: "long" })}\n${d.toLocaleTimeString("en-US", { timeStyle: "short" })}`;

export const humanizeMidDate = (d: Date) =>
  d.toLocaleString("es-PY", { dateStyle: "medium", timeStyle: "short" });

export const humanizeShortDate = (d: Date) =>
  `${d.toLocaleDateString("en-GB")} ${d.toLocaleTimeString("en-US", { timeStyle: "short" })}`;

export const toInternalFullDate = (d: Date) =>
  d.toLocaleString("es-CL", { timeZoneName: "short" });
