import { Icon } from "@iconify/react";

import useGeneralSettingsPreviews from "api/hooks/useGlobalSettingsPreviewsQuery";
import { CustomMetas } from "components/Feedback/Suspensions/types";
import Skeleton from "components/Skeleton";
import { GenerateGlobalSettingPreviewResponse } from "feedback-api";

import "./GeneralConfigurationFormPreview.css";

interface GeneralConfigurationFormPreviewProps {
  customMetas: CustomMetas;
  previewType: keyof GenerateGlobalSettingPreviewResponse;
}
const GeneralConfigurationFormPreview = ({
  customMetas,
  previewType,
}: GeneralConfigurationFormPreviewProps) => {
  const { data, isPending, isError } = useGeneralSettingsPreviews(customMetas);

  return (
    <div className="GeneralConfigurationFormPreview__message">
      <div className="GeneralConfigurationFormPreview__message_text">
        {isError ? (
          <div className="GeneralConfigurationDrawer__message_with_loader">
            <Icon icon="uil:exclamation-triangle" />
            Error al obtener las previsualizaciones
          </div>
        ) : isPending ? (
          <Skeleton />
        ) : (
          data[previewType]
        )}
      </div>
    </div>
  );
};

export default GeneralConfigurationFormPreview;
