import { CandidateAppointment } from "feedback-api";

import { CustomMetas } from "../types";
import { ProfessionalOption } from "./NewSuspension";
import {
  createNewSuspensionContextState,
  NewSuspensionContextState,
} from "./NewSuspensionContextState";

export type NewSuspensionAction =
  | { type: "SET_START_DATE"; payload: Date }
  | { type: "SET_END_DATE"; payload: Date }
  | {
      type: "SET_SELECTED_PROFESSIONAL";
      payload: ProfessionalOption;
    }
  | {
      type: "SET_SUSPENSION_APPOINTMENTS";
      payload: {
        selectedAppointments: CandidateAppointment[];
        excludedCandidateIds: string[];
      };
    }
  | {
      type: "SET_CUSTOM_METAS";
      payload: CustomMetas;
    }
  | { type: "RESET" };

export function newSuspensionReducer(
  context: NewSuspensionContextState,
  action: NewSuspensionAction,
): NewSuspensionContextState {
  switch (action.type) {
    case "SET_START_DATE":
      return {
        ...context,
        startDate: action.payload,
      };
    case "SET_END_DATE":
      return {
        ...context,
        endDate: action.payload,
      };
    case "SET_SELECTED_PROFESSIONAL":
      return {
        ...context,
        selectedProfessional: action.payload,
      };
    case "SET_SUSPENSION_APPOINTMENTS":
      return {
        ...context,
        ...action.payload,
      };
    case "SET_CUSTOM_METAS":
      return {
        ...context,
        customMetas: action.payload,
      };
    case "RESET":
      return createNewSuspensionContextState();
  }
}
