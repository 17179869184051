import AttachmentMessage from "./AttachmentMessage";
import AudioMessage from "./AudioMessage";
import OutboundAttachmentMessage from "./OutboundAttachmentMessage";
import PictureMessage from "./PictureMessage";
import TextMessage from "./TextMessage";
import VCardMessage from "./VCardMessage";
import VideoMessage from "./VideoMessage";

export const attachmentsToken = "ATTACHMENT:";

const isOutboundAttachment = (message: string): boolean => {
  return message.indexOf(attachmentsToken) >= 0;
};

const isAttachment = (message: string): boolean => {
  return message === "MEDIAFILEURL";
};

const isPicture = (message: string): boolean => {
  return message === "MEDIAIMAGEURL";
};

const isAudio = (message: string): boolean => {
  return message === "MEDIAAUDIOURL";
};

const isVideo = (message: string): boolean => {
  return message === "MEDIAVIDEOURL";
};

const isVCard = (message: string): boolean => {
  return message === "MEDIAVCARDURL";
};

export const getMessageContentComponent = (
  message: string,
  messageId: number,
): React.ReactElement => {
  if (isAttachment(message)) {
    return <AttachmentMessage answerId={messageId} />;
  }
  if (isPicture(message)) {
    return <PictureMessage answerId={messageId} />;
  }
  if (isAudio(message)) {
    return <AudioMessage answerId={messageId} />;
  }
  if (isVideo(message)) {
    return <VideoMessage answerId={messageId} />;
  }
  if (isVCard(message)) {
    return <VCardMessage answerId={messageId} />;
  }
  if (isOutboundAttachment(message)) {
    return <OutboundAttachmentMessage message={message} />;
  }
  return <TextMessage message={message} />;
};
