import { startOfToday, endOfToday } from "date-fns";

import { CandidateAppointment } from "feedback-api";

import { CustomMetas } from "../types";
import { ProfessionalOption } from "./NewSuspension";

export interface NewSuspensionContextState {
  startDate: Date;
  endDate: Date;
  selectedProfessional?: ProfessionalOption;
  selectedAppointments?: CandidateAppointment[];
  excludedCandidateIds?: string[];
  customMetas?: CustomMetas;
}

export const DATETIME_LOCAL_FORMAT = "yyyy-LL-dd'T'HH:mm";

export const createNewSuspensionContextState =
  (): NewSuspensionContextState => {
    return {
      startDate: startOfToday(),
      endDate: endOfToday(),
    };
  };
