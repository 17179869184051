import { useDispatch, useSelector } from "react-redux";

import Skeleton from "components/Skeleton";
import PaginationControls from "components/molecules/PaginationControls";
import { respuestasSelector, setPagina } from "store/slices/respuestas";

import "./FooterTablaRespuestas.css";

interface FooterTablaRespuestasProps {
  cargando: boolean;
  respuestasPorPagina: number;
  totalRespuestas: number;
}

const FooterTablaRespuestas = ({
  cargando,
  respuestasPorPagina,
  totalRespuestas,
}: FooterTablaRespuestasProps) => {
  const numeroPaginas = Math.ceil(totalRespuestas / respuestasPorPagina);
  const { pagina } = useSelector(respuestasSelector);

  const dispatch = useDispatch();

  const mensaje =
    totalRespuestas === 0 ? (
      <></>
    ) : (
      <>
        Mostrando{" "}
        {(respuestasPorPagina * (pagina - 1) + 1).toLocaleString("es-CL")}
        &ndash;
        {Math.min(respuestasPorPagina * pagina, totalRespuestas).toLocaleString(
          "de-DE",
        )}{" "}
        de{" "}
        <span className="FooterTablaRespuestas__numero_total">
          {totalRespuestas.toLocaleString("es-CL")}
        </span>
      </>
    );

  return (
    <div className="FooterTablaRespuestas">
      {cargando ? (
        <>
          <Skeleton />
          <Skeleton />
        </>
      ) : (
        <>
          <p className="FooterTablaRespuestas__total">{mensaje}</p>
          {totalRespuestas > respuestasPorPagina && (
            <PaginationControls
              pages={numeroPaginas}
              current={pagina}
              setCurrent={(newPagina) => dispatch(setPagina(newPagina))}
            />
          )}
        </>
      )}
    </div>
  );
};

export default FooterTablaRespuestas;
