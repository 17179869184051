import { useMutation } from "@tanstack/react-query";

import { authApi, AuthRequest } from "feedback-api";

const useLoginMutation = () => {
  return useMutation({
    mutationKey: ["login"],
    mutationFn: async (auth: AuthRequest) => {
      const { data } = await authApi.loginV4AuthLoginPost(auth);
      return data;
    },
  });
};

export default useLoginMutation;
