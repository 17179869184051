import useMetricsFiltersQuery from "api/hooks/useMetricsFiltersQuery";
import Loader from "components/atoms/Loader";

import "./DashboardDataFilters.css";
import MultiSelect from "./MultiSelect";

const DashboardDataFilters = () => {
  const { data, isPending } = useMetricsFiltersQuery();
  return (
    <div className="DashboardDataFilters">
      <h3 className="DashboardDataFilters__title">Filtros</h3>
      {isPending ? (
        <Loader />
      ) : (
        data?.map((property) => (
          <MultiSelect
            key={`DashboardDataFilters__${property.id}`}
            property={property}
          />
        ))
      )}
    </div>
  );
};

export default DashboardDataFilters;
