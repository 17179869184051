import configuration from "./configuration";
import {
  AuthenticationApiFactory,
  CampaignsApiFactory,
  Configuration,
  DashboardsApiFactory,
  DebtorsApiFactory,
  SuspensionsApiFactory,
  ConfigFieldsApiFactory,
  IssuesApiFactory,
} from "./generated";

export type {
  AuthRequest,
  CampaignConfig,
  GetConfigFieldsResponse,
  MessagePreview,
  MetricCount,
  MetricFilter,
  PostSpreadsheetResponse,
  Campaign,
  CandidateAppointment,
  CreateSuspensionRequest,
  CreateSuspensionResponse,
  ErrorResponse,
  DefaultValue,
  Suspension,
  SelectOption,
  GenerateGlobalSettingPreviewResponse,
  UpdateGlobalSettingsRequest,
} from "./generated";

export { CampaignStatus, ErrorCode, MetricFilterKindEnum } from "./generated";

export const authApi = AuthenticationApiFactory(
  new Configuration({ ...configuration, accessToken: undefined }),
);

export const campaignsApi = CampaignsApiFactory(configuration);
export const dashboardsApi = DashboardsApiFactory(configuration);
export const debtorsApi = DebtorsApiFactory(configuration);
export const issuesApi = IssuesApiFactory(configuration);
export const suspensionsApi = SuspensionsApiFactory(configuration);
export const configFieldsApi = ConfigFieldsApiFactory(configuration);
