import { isAxiosError } from "axios";

import { ErrorCode } from "feedback-api";

export const getErrorMessageForCode = (error: Error) => {
  switch (isAxiosError(error) && error.response?.data.error_code) {
    case ErrorCode.ConnectionError:
      return "Lo sentimos, no pudimos conectarnos con la agenda en este momento. Por favor, intenta de nuevo más tarde.";
    default:
      return "Hubo un problema al obtener las citas de la agenda. Por favor, intenta de nuevo más tarde.";
  }
};
