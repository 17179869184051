import { toCanvas } from "html-to-image";

const IMAGE_PLACEHOLDER =
  "data:image/gif;base64,R0lGODlhAQABAIABAFVN9f///yH5BAEKAAEALAAAAAABAAEAAAICRAEAOw==";

const TARGET_VIEW_WIDTH_PX = 800;
const TARGET_CHAT_WIDTH_PX = 300;

export const getBlobsFromInteractionDrawer = async () => {
  return await getBlobs({
    mainContainerClass: "InteractionDrawer",
    messagesContainerClass: "Smartphone__messages_container",
    notCurrentElementsSelector: ".Smartphone__element_not_current",
  });
};

export const getBlobsFromChat = async () => {
  return await getBlobs({
    mainContainerClass: "Feedback__contenedor_central",
    messagesContainerClass: "CelularWhatsapp__contenedor_mensajes",
    notCurrentElementsSelector:
      ".CelularWhatsapp__contenedor_conversacion:not(.CelularWhatsapp__contenedor_conversacion--seleccionada)",
  });
};

const getJpegBlob = (b: HTMLCanvasElement) =>
  new Promise<Blob | null>((resolve) => b.toBlob(resolve, "image/jpeg", 0.8));

const getBlobs = async ({
  mainContainerClass,
  messagesContainerClass,
  notCurrentElementsSelector,
}: {
  mainContainerClass: string;
  messagesContainerClass: string;
  notCurrentElementsSelector: string;
}) => {
  const previousDisplayStyles = Array.from(
    document.querySelectorAll<HTMLElement>(notCurrentElementsSelector),
  ).map((nodo) => nodo.style.display);
  document
    .querySelectorAll<HTMLElement>(notCurrentElementsSelector)
    .forEach((nodo) => {
      nodo.style.display = "none";
    });

  const mainContainerElement = document.getElementsByClassName(
    mainContainerClass,
  )[0] as HTMLElement;
  const mainContainerCanvas = await toCanvas(mainContainerElement, {
    width: mainContainerElement.scrollWidth,
    height: mainContainerElement.scrollHeight,
    canvasWidth: TARGET_VIEW_WIDTH_PX,
    canvasHeight:
      (TARGET_VIEW_WIDTH_PX * mainContainerElement.scrollHeight) /
      mainContainerElement.scrollWidth,
    pixelRatio: 1,
    backgroundColor: "#fff",
    imagePlaceholder: IMAGE_PLACEHOLDER,
  });
  const mainContainerBlob = await getJpegBlob(mainContainerCanvas);

  const messagesContainerElement = document.getElementsByClassName(
    messagesContainerClass,
  )[0] as HTMLElement;
  const prevMaxHeight = messagesContainerElement.style.maxHeight;
  messagesContainerElement.style.overflow = "visible";
  messagesContainerElement.style.maxHeight = "auto";
  const messagesContainerCanvas = await toCanvas(messagesContainerElement, {
    width: messagesContainerElement.scrollWidth,
    height: messagesContainerElement.scrollHeight,
    canvasWidth: TARGET_CHAT_WIDTH_PX,
    canvasHeight:
      (TARGET_CHAT_WIDTH_PX * messagesContainerElement.scrollHeight) /
      messagesContainerElement.scrollWidth,
    pixelRatio: 1,
    backgroundColor: "#fff",
    imagePlaceholder: IMAGE_PLACEHOLDER,
  });
  const messagesContainerBlob = await getJpegBlob(messagesContainerCanvas);

  messagesContainerElement.style.overflow = "auto";
  messagesContainerElement.style.maxHeight = prevMaxHeight;
  document
    .querySelectorAll<HTMLElement>(notCurrentElementsSelector)
    .forEach((nodo, i) => {
      nodo.style.display = previousDisplayStyles[i];
    });
  return { mainContainerBlob, messagesContainerBlob };
};
