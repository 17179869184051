import { Icon } from "@iconify/react";
import classNames from "classnames";
import { parseISO } from "date-fns";
import { useSelector } from "react-redux";

import { AnswerHeader, ComputedHeader, Respuesta } from "api/types/domain";
import InteractionNoteIcon from "components/Feedback/InteractionDrawer/InteractionNotes/InteractionNote/InteractionNoteIcon";
import { Emoji } from "components/Feedback/InteractionDrawer/InteractionNotes/InteractionNote/InteractionNoteIcon/emojis";
import TagRespuesta from "components/Feedback/Respuestas/TablaRespuestas/TagRespuesta";
import Scrambler from "components/Scrambler";
import { formatearCampoRespuestas, formatearFecha } from "helpers/respuestas";
import { getTags } from "helpers/tags";
import useAnalytics from "hooks/useAnalytics";
import { respuestasSelector } from "store/slices/respuestas";

import "./FilaTablaRespuestas.css";

interface FilaTablaRespuestasProps {
  respuesta: Respuesta;
  indice: number;
  onClick: () => void;
  headers: (AnswerHeader | ComputedHeader)[];
}

const FilaTablaRespuestas = ({
  respuesta,
  indice,
  onClick,
  headers,
}: FilaTablaRespuestasProps) => {
  const { columnaDestacada, indiceRespuestaSeleccionada: filaTablaDestacada } =
    useSelector(respuestasSelector);
  const track = useAnalytics();

  if (!respuesta) {
    return null;
  }

  const ultimaReaccion = respuesta.reactions.slice(-1)[0];

  const respuestaManipulada = { ...respuesta };
  if (respuesta.n_appointments) {
    Object.keys(respuesta).forEach((h) => {
      if (
        h.match(/_[0-9]$/) &&
        Number(h.slice(-1)) > respuesta.n_appointments
      ) {
        respuestaManipulada[h] = "";
      }
    });
  }

  let fechaAgregadaLegible = "";

  if (ultimaReaccion) {
    fechaAgregadaLegible = formatearFecha(
      parseISO(ultimaReaccion.created_at),
      true,
    );
  }

  return (
    <tr
      className={classNames({
        FilaTablaRespuestas: true,
        "FilaTablaRespuestas--destacada": indice === filaTablaDestacada,
      })}
      onClick={onClick}
      title={
        indice === filaTablaDestacada
          ? "Este fue el último chat que revisaste"
          : ""
      }
    >
      <td className="FilaTablaRespuestas__celda FilaTablaRespuestas__celda--sin-padding">
        {ultimaReaccion && (
          <span className="FilaTablaRespuestas__contenedor_reaccion">
            <InteractionNoteIcon
              emoji={ultimaReaccion.reaction_emoji as Emoji}
            />
            {ultimaReaccion.reaction_text && (
              <span
                className="FilaTablaRespuestas__contenedor_nota_indicador_nota"
                onMouseEnter={() =>
                  track("Feedback", "Respuestas", "verPopupNota", {
                    texto: ultimaReaccion.reaction_text,
                    emoji: ultimaReaccion.reaction_emoji,
                  })
                }
              >
                {ultimaReaccion.reaction_text}{" "}
                <span
                  style={{
                    fontStyle: "italic",
                    opacity: 0.8,
                    paddingLeft: ".2rem",
                  }}
                >
                  {fechaAgregadaLegible}
                </span>
              </span>
            )}
          </span>
        )}
      </td>
      {headers.map((header, j) => {
        const { nombre, texto, tipo } = header;
        // TODO: Remove when computed field are migrated to new spec
        const valorHeader =
          "f" in header
            ? header.f(respuestaManipulada)
            : respuestaManipulada[nombre];
        return (
          <td
            key={`celda-respuesta-${indice}-${j}`}
            className={classNames({
              FilaTablaRespuestas__celda: true,
              "FilaTablaRespuestas__celda--destacada": columnaDestacada === j,
            })}
          >
            {tipo === "ICON" ? (
              <div title={valorHeader.label}>
                <Icon
                  className="FilaTablaRespuestas__channel_icon"
                  icon={`mdi:${valorHeader.icon}`}
                />
              </div>
            ) : valorHeader && valorHeader.tag !== undefined ? (
              <span
                className="FilaTablaRespuestas__contenedor_tag"
                title={valorHeader.text}
              >
                {getTags(valorHeader.tag).map((tag) => (
                  <TagRespuesta
                    key={tag.id}
                    tag={tag}
                    pregunta={texto}
                    contactadoPorTelefono={
                      respuestaManipulada.is_unreachable?.whatsapp &&
                      !respuestaManipulada.is_unreachable?.phone
                    }
                  />
                ))}
              </span>
            ) : tipo === "COMPUTED" ? (
              <span
                className="FilaTablaRespuestas__contenedor_tag"
                title={valorHeader}
              >
                {getTags(valorHeader).map((tag) => (
                  <TagRespuesta key={tag.id} tag={tag} pregunta={texto} />
                ))}
              </span>
            ) : (
              <Scrambler tipo={nombre}>
                {formatearCampoRespuestas(valorHeader, nombre)}
              </Scrambler>
            )}
          </td>
        );
      })}
    </tr>
  );
};

export default FilaTablaRespuestas;
