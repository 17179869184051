import { Icon } from "@iconify/react";
import { EventHandler, SyntheticEvent } from "react";

import "./Checkbox.css";

interface CheckboxProps {
  checked?: boolean;
  indeterminate?: boolean;
  disabled?: boolean;
  onChange?: EventHandler<SyntheticEvent<HTMLElement>>;
}

const Checkbox = ({
  checked = false,
  indeterminate = false,
  disabled = false,
  onChange,
}: CheckboxProps) => {
  return (
    <button
      role="checkbox"
      aria-checked={checked}
      className="Checkbox"
      disabled={disabled}
      type="button"
      onClick={onChange}
    >
      {indeterminate ? (
        <Icon icon="uil:minus" />
      ) : (
        checked && <Icon icon="uil:check" />
      )}
    </button>
  );
};

export default Checkbox;
