import { Icon, InlineIcon } from "@iconify/react";
import classNames from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { ChatAPIConversationContextField } from "api/types/responses";
import Scrambler from "components/Scrambler";
import { formatearCampoRespuestas } from "helpers/respuestas";
import useAnalytics from "hooks/useAnalytics";
import useWhatsappLink from "hooks/useWhatsappLink";
import { encuestasSelector } from "store/slices/encuestas";
import { guardaIdRespuesta, respuestasSelector } from "store/slices/respuestas";

import HistorialContactosModal from "../HistorialContactosModal";
import LoaderChat from "../LoaderChat";
import "./DatosChat.css";

interface DatosChatProps {
  cargando: boolean;
  datos: ChatAPIConversationContextField[] | undefined;
  telefono: string;
  intentos: {
    phone: string;
    timestamp: string;
  }[];
}

const DatosChat = ({ cargando, datos, telefono, intentos }: DatosChatProps) => {
  const [intentosVisibles, setIntentosVisibles] = useState(false);
  const [showInteractionHistoryModal, setInteractionHistoryModal] =
    useState(false);
  const { respuestasVisibles: respuestas, indiceRespuestaSeleccionada } =
    useSelector(respuestasSelector);
  const { idEncuestaSeleccionada: idEncuesta } = useSelector(encuestasSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const track = useAnalytics();
  const whatsappLink = useWhatsappLink(telefono);

  const haySiguienteChat =
    respuestas &&
    indiceRespuestaSeleccionada !== undefined &&
    indiceRespuestaSeleccionada < respuestas.length - 1;
  const hayChatAnterior =
    respuestas &&
    indiceRespuestaSeleccionada !== undefined &&
    indiceRespuestaSeleccionada > 0;

  const irARespuestaAnterior = useCallback(() => {
    track("Feedback", "Chat", "anterior", { idEncuesta });
    if (!datos || !hayChatAnterior) {
      return;
    }
    const respuestaAnterior = respuestas[indiceRespuestaSeleccionada - 1];
    dispatch(guardaIdRespuesta(indiceRespuestaSeleccionada - 1));
    navigate(
      `/respuestas/${idEncuesta}/${respuestaAnterior.user_id}?interaccion=${respuestaAnterior.interaction_id}`,
    );
  }, [
    datos,
    dispatch,
    navigate,
    indiceRespuestaSeleccionada,
    idEncuesta,
    respuestas,
    hayChatAnterior,
    track,
  ]);

  const irASiguienteRespuesta = useCallback(() => {
    track("Feedback", "Chat", "siguiente", { idEncuesta });
    if (!datos || !haySiguienteChat) {
      return;
    }
    const siguienteRespuesta = respuestas[indiceRespuestaSeleccionada + 1];
    dispatch(guardaIdRespuesta(indiceRespuestaSeleccionada + 1));
    navigate(
      `/respuestas/${idEncuesta}/${siguienteRespuesta.user_id}?interaccion=${siguienteRespuesta.interaction_id}`,
    );
  }, [
    datos,
    dispatch,
    navigate,
    indiceRespuestaSeleccionada,
    idEncuesta,
    respuestas,
    haySiguienteChat,
    track,
  ]);

  const datosFixMulticitas = useMemo(() => {
    if (!datos) {
      return undefined;
    }
    const nCitas = datos.find((d) => d.title === "N Citas")?.value;
    if (!nCitas) {
      return datos;
    }
    return datos.filter(
      (d) =>
        d.target.search(/_[0-9]$/) < 0 ||
        Number(d.target.slice(-1)[0]) <= Number(nCitas),
    );
  }, [datos]);

  useEffect(() => {
    const teclasMagicas = (e: KeyboardEvent) => {
      if (e.code === "PageUp" || e.code === "ArrowLeft") {
        track("Feedback", "Chat", "anteriorConTeclado", { idEncuesta });
        irARespuestaAnterior();
      } else if (e.code === "PageDown" || e.code === "ArrowRight") {
        track("Feedback", "Chat", "siguienteConTeclado", { idEncuesta });
        irASiguienteRespuesta();
      }
    };
    window.addEventListener("keyup", teclasMagicas);
    return () => window.removeEventListener("keyup", teclasMagicas);
  }, [irARespuestaAnterior, irASiguienteRespuesta, track, idEncuesta]);

  const urlAnterior = location.state?.from;

  return (
    <div className="DatosChat">
      <div className="DatosChat__navegacion">
        <button
          className="DatosChat__link_atras"
          onClick={() => {
            track(
              "Feedback",
              "Chat",
              `volverA${
                urlAnterior
                  ? urlAnterior[1].toUpperCase() + urlAnterior.slice(2)
                  : "Respuestas"
              }`,
            );
            navigate(urlAnterior ?? "/respuestas");
          }}
          title={`Volver a ${
            urlAnterior ? urlAnterior.slice(1) : "respuestas"
          }`}
        >
          <InlineIcon
            className="DatosChat__icono_volver"
            icon="mdi:arrow-left"
          />
          {urlAnterior
            ? urlAnterior[1].toUpperCase() + urlAnterior.slice(2)
            : "Respuestas"}
        </button>
        {!urlAnterior &&
          respuestas &&
          respuestas.length > 0 &&
          indiceRespuestaSeleccionada !== undefined && (
            <div className="DatosChat__botones_navegacion">
              <button
                className="DatosChat__link_anterior"
                onClick={irARespuestaAnterior}
                disabled={cargando || !hayChatAnterior || !datos}
                title={
                  hayChatAnterior
                    ? "Ver chat anterior (←)"
                    : "Este es el primer chat de la tabla"
                }
              >
                <InlineIcon
                  className="DatosChat__icono_anterior"
                  icon="mdi:play"
                />
              </button>
              <div className="DatosChat__posicion">
                Chat {(indiceRespuestaSeleccionada + 1).toLocaleString("de-DE")}{" "}
                / {respuestas.length.toLocaleString("de-DE")}
              </div>
              <button
                className="DatosChat__link_siguiente"
                onClick={irASiguienteRespuesta}
                disabled={cargando || !haySiguienteChat || !datos}
                title={
                  haySiguienteChat
                    ? "Ver chat siguiente (→)"
                    : "Este es el último chat de la tabla"
                }
              >
                <InlineIcon
                  className="DatosChat__icono_siguiente"
                  icon="mdi:play"
                />
              </button>
            </div>
          )}
      </div>
      <div className="DatosChat__header">
        <h1 className="DatosChat__titulo">Datos del chat</h1>
        <button
          className="DatosChat__boton_historial"
          onClick={() =>
            setInteractionHistoryModal(!showInteractionHistoryModal)
          }
          title="Historial de Interacciones"
        >
          <Icon icon="mdi:history" />
        </button>
        {showInteractionHistoryModal && (
          <HistorialContactosModal
            close={() => setInteractionHistoryModal(false)}
          />
        )}
      </div>
      {!cargando && datos !== undefined ? (
        <div className="DatosChat__contenedor_datos">
          {intentos.length > 0 && (
            <div
              className={classNames({
                DatosChat__contenedor_reintentos: true,
                "DatosChat__contenedor_reintentos--visible": intentosVisibles,
              })}
            >
              <div className="DatosChat__reintentos">
                {intentos.length > 1 ? (
                  <>
                    Los siguientes números asociados a la cita
                    <br /> no tienen WhatsApp
                  </>
                ) : (
                  <>
                    El siguiente número asociado a la cita
                    <br /> no tiene WhatsApp
                  </>
                )}
                :
                {intentos.map((intento, i) => (
                  <div key={i} className={`intento-${i}`}>
                    {formatearCampoRespuestas(intento.phone, "phone")}
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="DatosChat__contenedor_header">
            <div className="DatosChat__nombre_header">
              <span>Teléfono</span>
              {intentos.length > 0 && (
                <Icon
                  className="DatosChat__icono_reintentos"
                  icon="mdi:info"
                  onMouseEnter={() => setIntentosVisibles(true)}
                  onMouseLeave={() => setIntentosVisibles(false)}
                />
              )}
            </div>
            <div className="DatosChat__valor_header">
              <Scrambler tipo="telefono">
                {formatearCampoRespuestas(telefono, "phone")}
              </Scrambler>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={whatsappLink}
                className="DatosChat__link_ws"
                title="Chatear en WhatsApp"
                onClick={() =>
                  track("Feedback", "Chat", "abrirWhatsappWebDesdeDatosChat", {
                    link: whatsappLink,
                  })
                }
              >
                <InlineIcon icon="mdi:whatsapp" />
              </a>
            </div>
            <button
              className="DatosChat__boton_copiar"
              onClick={() => {
                navigator.clipboard.writeText(telefono);
                track("Feedback", "Chat", "copia", {
                  campo: "teléfono",
                  valor: telefono,
                });
              }}
            >
              <Icon icon="mdi:content-copy" /> Copiar
            </button>
          </div>
          {datosFixMulticitas?.map(
            ({ value: nombre, title: texto, target }, i) => (
              <div
                key={`header-chat-${i}`}
                className="DatosChat__contenedor_header"
              >
                <div className="DatosChat__nombre_header">{texto}</div>
                <div className="DatosChat__valor_header">
                  <Scrambler propagar={true} tipo={target}>
                    {formatearCampoRespuestas(nombre, target)}
                  </Scrambler>
                </div>
                <button
                  className="DatosChat__boton_copiar"
                  onClick={() => {
                    navigator.clipboard.writeText(nombre);
                    track("Feedback", "Chat", "copia", {
                      campo: texto,
                      valor: nombre,
                    });
                  }}
                >
                  <Icon icon="mdi:content-copy" /> Copiar
                </button>
              </div>
            ),
          )}
        </div>
      ) : (
        <LoaderChat />
      )}
    </div>
  );
};

export default DatosChat;
