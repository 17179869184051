import { useMutation } from "@tanstack/react-query";

import { suspensionsApi } from "feedback-api";
import type {
  CreateSuspensionRequest,
  CreateSuspensionResponse,
} from "feedback-api";

const useCreateSuspensionMutation = () => {
  return useMutation<CreateSuspensionResponse, Error, CreateSuspensionRequest>({
    mutationFn: async (payload) => {
      const { data } =
        await suspensionsApi.createSuspensionV4SuspensionsPost(payload);
      return data;
    },
  });
};

export default useCreateSuspensionMutation;
